import { StepDefinition } from '../../types';

export default {
  key: 'search_linkedin_posts',
  name: 'Search Linkedin Posts',
  description: 'Searches LinkedIn posts.',
  icon: 'i-logos-linkedin-icon',
  category: 'integrations',
  services: ['linkedin'],
  options: {
    query: {
      key: 'query',
      name: 'Search Query',
      description: 'The search query.',
      type: 'text/plain',
      isOptional: false,
      metadata: {
        icon: 'i-ph-subtitles-bold',
      },
      listDepth: 0,
      value: null,
    },
  },
  outputs: {
    posts: {
      key: 'posts',
      name: 'Posts',
      isOptional: false,
      type: 'object',
      listDepth: 1,
      metadata: {
        schema: {
          poster: {
            key: 'poster',
            name: 'Poster',
            description: 'The user who created this post.',
            type: 'text/plain',
            isOptional: false,
            listDepth: 0,
            value: null,
          },
          content: {
            key: 'content',
            name: 'Content',
            description: 'The content of the post',
            type: 'text/plain',
            isOptional: false,
            listDepth: 0,
            value: null,
          },
        },
      },
      value: null,
    },
  },
} satisfies StepDefinition;
