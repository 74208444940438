import { StepDefinition } from '../../types';

export default {
  key: 'anon_comment_instagram_post',
  name: 'Comment Instagram Post',
  description:
    'Given a link to a post on Instagram, comment on it from your account',
  icon: 'i-skill-icons-instagram',
  category: 'integrations',
  services: ['instagram'],
  options: {
    post: {
      key: 'post',
      name: 'Post',
      description: 'The link to the post that you would like to comment on',
      type: 'text/plain',
      isOptional: false,
      listDepth: 0,
      value: null,
    },
    message: {
      key: 'message',
      name: 'Message',
      description: 'The content of the comment',
      type: 'text/plain',
      isOptional: false,
      listDepth: 0,
      metadata: {},
      value: null,
    },
  },
  outputs: {},
} satisfies StepDefinition;
