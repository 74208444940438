import { StepDefinition } from '../../types';

export default {
  key: 'send_linkedin_dm',
  name: 'Send LinkedIn DM',
  description: 'Sends messages to a LinkedIn account .',
  icon: 'i-logos-linkedin-icon',
  category: 'integrations',
  services: ['linkedin'],
  options: {
    recipient: {
      key: 'recipient',
      name: 'Name',
      description: "The account's linkedin profile ID.",
      type: 'text/plain',
      isOptional: false,
      metadata: {
        icon: 'i-ph-subtitles-bold',
      },
      listDepth: 0,
      value: null,
    },
    message: {
      key: 'message',
      name: 'Message',
      description: "The message to send.",
      type: 'text/plain',
      isOptional: false,
      metadata: {
        icon: 'i-ph-message-circle-bold',
      },
      listDepth: 0,
      value: null,
    },
  },
  outputs: {
  },
} satisfies StepDefinition;
