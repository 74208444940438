import type { SubscriptionType } from '@respell/database';
import { isProd } from '@respell/utils/config';

export const taskCost = 0.015;

export const stripeTeamMonthlyPriceId = isProd
  ? 'price_1PRLbLAX72UmFuzdgnADoMTM'
  : 'price_1PORfBAX72UmFuzdxAq7ODqA';
export const stripeTeamYearlyPriceId = isProd
  ? 'price_1PRgnTAX72UmFuzd2xjOblNI'
  : 'price_1PORfNAX72UmFuzddNLSkNsB';
export const stripeStarterMonthlyPriceId = isProd
  ? 'price_1PRLZvAX72UmFuzdP3JcGKn3'
  : 'price_1PORUEAX72UmFuzd6moPDwto';
export const stripeStarterYearlyPriceId = isProd
  ? 'price_1PRLZvAX72UmFuzdR8IUPajm'
  : 'price_1PORV2AX72UmFuzdiVX5gz0U';
export const stripeMonthlyTaskPriceId = isProd
  ? 'price_1PxaYfAX72UmFuzd3XFM8Bt2'
  : 'price_1Pv4nJAX72UmFuzdC23uitOc';
export const stripeYearlyTaskPriceId = isProd
  ? 'price_1PxaYuAX72UmFuzdOb9d0QtW'
  : 'price_1PxJhxAX72UmFuzdZiVbbIf0';

export const sourceToIcon = {
  GOOGLE_DRIVE: 'i-logos-google-drive',
  NOTION: 'i-logos-notion-icon',
};

enum FileFormatIcons {
  code = 'i-ph-file-code',
  file = 'i-ph-file',
  folder = 'i-ph-folder-simple',
  audio = 'i-ph-file-audio',
  pdf = 'i-ph-file-pdf',
  spreadsheet = 'i-ph-grid-nine',
  googleDocument = 'i-mdi-file-document',
  googleSpreadsheet = 'i-mdi-google-spreadsheet',
  googlePresentation = 'i-mdi-file-presentation-box',
  text = 'i-ph-file-txt',
  doc = 'i-ph-file-doc',
  mp3 = 'i-ph-file-audio',
  png = 'i-ph-file-png',
  jpg = 'i-ph-file-jpg',
  presentation = 'i-ph-browser',
}

export const fileFormatToIcon = {
  CSV: FileFormatIcons.spreadsheet,
  DOCX: FileFormatIcons.text,
  GOOGLE_DOCS: FileFormatIcons.googleDocument,
  GOOGLE_SHEETS: FileFormatIcons.googleSpreadsheet,
  GOOGLE_SLIDES: FileFormatIcons.googlePresentation,
  MD: FileFormatIcons.text,
  PNG: FileFormatIcons.png,
  MP3: FileFormatIcons.mp3,
  PDF: FileFormatIcons.pdf,
  JPG: FileFormatIcons.jpg,
  JPEG: FileFormatIcons.jpg,
  NOTION: FileFormatIcons.text,
  RTF: FileFormatIcons.text,
  TSV: FileFormatIcons.spreadsheet,
  TXT: FileFormatIcons.text,
  XLSX: FileFormatIcons.spreadsheet,
};

export const planToPlanName: Record<string, string> = {
  starter: 'Starter',
  team: 'Team',
  enterprise: 'Enterprise',
};

export const subscriptionTypeToDisplayName: Record<SubscriptionType, string> = {
  free: 'Respell Free',
  starter: 'Respell Starter',
  team: 'Respell Team',
  enterprise: 'Respell Enterprise',
  unknown: 'Unknown Plan',
  legacy: 'Respell Legacy',
};

export const subscriptionTypeToIconName: Record<SubscriptionType, string> = {
  free: 'i-ph-gift',
  starter: 'i-ph-lightning',
  team: 'i-ph-users',
  enterprise: 'i-ph-globe',
  unknown: 'i-ph-asterisk',
  legacy: 'i-ph-asterisk',
};

const baseFileSizeLimits: Record<string, string> = {
  'file/audio': '20MB',
  'file/document': '20MB',
  'file/image': '10MB',
  'file/spreadsheet': '20MB',
  'file/video': '100MB',
};

export const subscriptionTypeToFileSizeLimits: Record<
  SubscriptionType,
  string
> = {
  free: baseFileSizeLimits,
  starter: {
    ...baseFileSizeLimits,
    'file/audio': '100MB',
    'file/video': '1000MB', // 1GB
  },
  team: {
    ...baseFileSizeLimits,
    'file/audio': '100MB',
    'file/video': '1000MB', // 1GB
  },
  enterprise: {
    ...baseFileSizeLimits,
    'file/audio': '100MB',
    'file/video': '1000MB', // 1GB
  },
  unknown: baseFileSizeLimits,
  legacy: baseFileSizeLimits,
};
