import { StepDefinition } from '../../types';

export default {
  key: 'locate_companies',
  name: 'Locate Companies',
  description: '',
  icon: 'i-ph-map-pin-area',
  category: 'sdr',
  services: [],
  options: {
    nextSteps: {
      key: 'nextSteps',
      name: 'Next Steps',
      type: 'text/plain',
      isOptional: false,
      listDepth: 1,
      value: [],
    },
    companyProspectingGoal: {
      key: 'companyProspectingGoal',
      name: 'companyProspectingGoal',
      type: 'text/plain',
      isOptional: true,
      listDepth: 0,
      value: null,
    },
    technologies: {
      key: 'technologies',
      name: 'Technologies',
      description: 'The technologies used by the company',
      type: 'text/plain',
      isOptional: true,
      listDepth: 1,
      value: null,
    },
    hiring_for: {
      key: 'hiring_for',
      name: 'Hiring For',
      description: 'The roles the company is hiring for',
      type: 'text/plain',
      isOptional: true,
      listDepth: 1,
      value: null,
    },
    foundedYearMin: {
      key: 'foundedYear',
      name: 'Founded Year',
      description: 'The year the company was founded',
      type: 'text/plain',
      isOptional: true,
      listDepth: 0,
      value: null,
    },
    foundedYearMax: {
      key: 'foundedYear',
      name: 'Founded Year',
      description: 'The year the company was founded',
      type: 'text/plain',
      isOptional: true,
      listDepth: 0,
      value: null,
    },
    number_of_employees: {
      key: 'number_of_employees',
      name: 'Number of Employees',
      description: 'The number of employees in the company',
      type: 'text/plain',
      isOptional: true,
      listDepth: 1,
      value: null,
    },
    location: {
      key: 'location',
      name: 'Location',
      description: 'The location of the company',
      type: 'text/plain',
      isOptional: true,
      listDepth: 1,
      metadata: {
        icon: 'i-ph-map-pin',
      },
      value: null,
    },
    keywords: {
      key: 'keywords',
      name: 'Keywords',
      description: 'Keywords related to the company',
      type: 'text/plain',
      isOptional: true,
      listDepth: 1,
      metadata: {
        icon: 'i-ph-key',
      },
      value: null,
    },
    funding_stages: {
      key: 'funding_stages',
      name: 'Funding Stages',
      description: 'The funding stages of the company',
      type: 'text/plain',
      isOptional: true,
      listDepth: 1,
      metadata: {
        icon: 'i-ph-currency-dollar',
      },
      value: null,
    },
    industry: {
      key: 'industry',
      name: 'Industry',
      description: 'The industry the company belongs to',
      type: 'text/plain',
      isOptional: true,
      listDepth: 1,
      metadata: {
        icon: 'i-ph-briefcase',
      },
      value: null,
    },
    numberofleads: {
      key: 'numberofleads',
      name: 'Number of Leads',
      description: 'The number of leads to generate',
      type: 'number/integer',
      isOptional: true,
      listDepth: 0,
      metadata: {
        icon: 'i-ph-users',
      },
      value: null,
    },
  },
  outputs: {
    companiesList: {
      key: 'companiesList',
      name: 'companiesList',
      type: 'object',
      isOptional: false,
      listDepth: 1,
      metadata: {
        icon: 'i-ph-info',
      },
      value: null,
    },
  },
} satisfies StepDefinition;
