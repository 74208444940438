<script setup lang="ts">
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginGetFile from 'filepond-plugin-get-file';
import 'filepond-plugin-get-file/dist/filepond-plugin-get-file.min.css';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css';
import 'filepond/dist/filepond.min.css';
import createVueFilePond from 'vue-filepond';

const modelValue = defineModel<any>();

defineProps({
  acceptedFileTypes: {
    type: Array as PropType<string[]> | undefined,
    default: undefined,
  },
});

const FilePond = createVueFilePond(
  FilePondPluginFileValidateType,
  FilePondPluginImagePreview,
  FilePondPluginGetFile,
  FilePondPluginFileValidateSize,
);

const serverConfig = {
  process: (fieldName, file, metadata, load, error, progress, abort) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      try {
        const content = JSON.parse(e.target.result as string);
        load(content);
        // Directly update modelValue here
        modelValue.value = content;
      } catch (err) {
        error('Invalid JSON file');
      }
    };
    reader.onerror = () => {
      error('Unable to read file');
    };
    reader.readAsText(file);
  },
};

// This function is no longer needed, but you can keep it for debugging if desired
const handleProcessFile = (error, file) => {
  if (error) {
    console.error('Error processing file:', error);
  }
};
</script>
<template>
  <FilePond
    :server="serverConfig"
    :allow-multiple="false"
    :accepted-file-types="acceptedFileTypes"
    @processfile="handleProcessFile"
  />
</template>
