<script setup lang="ts">
import type { Variable } from '@respell/utils';
import AppInput from '~/components/app/AppInput.vue';
import ContextInput from '~/components/editor/config/ContextInput.vue';

const modelValue = defineModel<Record<string, any>>();

defineProps({
  option: {
    type: Object as PropType<Variable>,
    required: true,
  },
  hasContext: {
    type: Boolean,
    default: false,
  },
  injectable: {
    type: Boolean,
    default: false,
  },
  size: {
    type: String,
    default: 'md',
  },
  formKey: {
    type: String,
    default: null,
  },
  unwrap: {
    type: Boolean,
    default: false,
  },
});

// TODO: Remove this when Nuxt UI fixes nested popovers
provide('isNested', true);

const handleChange = async (key: string, value: any) => {
  console.log('handleChange', key, value);
  if (!modelValue.value || typeof modelValue.value !== 'object') {
    modelValue.value = {};
    await until(modelValue).changed();
  }
  modelValue.value[key] = value;
};
</script>
<template>
  <div
    class="flex flex-col gap-4 p-m items-start"
    :class="{
      'bg-primary-50 shadow-sm rounded-xl border-primary-200 border': !unwrap,
    }"
  >
    <div v-if="hasContext" class="contents">
      <ContextInput
        v-for="(opt, key) in option.metadata?.schema"
        :key="key"
        :model-value="modelValue?.[key] ?? ''"
        :form-key="`${formKey ?? option.key}.${key}`"
        :option="opt"
        class="w-full"
        :injectable="injectable"
        :size="size"
        @update:model-value="(value) => handleChange(key, value)"
      />
    </div>
    <div v-else class="contents">
      <AppInput
        v-for="(opt, key) in option.metadata?.schema"
        :key="key"
        :model-value="modelValue?.[key] ?? ''"
        :form-key="`${formKey ?? option.key}.${key}`"
        :option="opt"
        class="w-full"
        :has-context="hasContext"
        :injectable="injectable"
        :size="size"
        @update:model-value="(value) => handleChange(key, value)"
      />
    </div>
  </div>
</template>
