import type { Graph, GraphRun, Spell } from '@respell/database';
import { type Json } from '@respell/utils';

export const useAgentsStore = defineStore('agents', () => {
  const workspaceStore = useWorkspaceStore();
  const userStore = useUserStore();
  const integrationStore = useIntegrationStore();

  const agentGraph = ref<Graph>(null);
  const agentSpellId = ref<string>(null);
  const campaigns = ref<GraphRun[]>([]);

  const reset = () => {
    agentGraph.value = null;
    agentSpellId.value = null;
    campaigns.value = [];
  };

  const configState = reactiveComputed(() => {
    const inputs = agentGraph.value?.inputs ?? {};
    return {
      productOrService: inputs.productOrService?.value ?? null,
      additionalCompanyInfo: inputs.additionalCompanyInfo?.value ?? null,
      companyWebsite: inputs.companyWebsite?.value ?? null,
      companyDescription: inputs.companyDescription?.value ?? null,
      companyName: inputs.companyName?.value ?? null,
    };
  });

  watchDebounced(
    configState,
    () => {
      updateConfig({ ...configState });
    },
    { debounce: 2000 },
  );

  const { linkedAccounts } = storeToRefs(integrationStore);
  const hasAccountsLinked = computed(
    () =>
      linkedAccounts.value.some((account) => account.service === 'gmail') &&
      linkedAccounts.value.some((account) => account.service === 'hubspot'),
  );

  const loadAgent = async () => {
    reset();

    const { data: agentSpell } = await useApi<Spell>('/api/agents/sdr', {
      query: {
        workspaceId: workspaceStore.workspaceId,
      },
    });

    if (!agentSpell.value) {
      await createAgent();
      return { status: 'new' };
    }
    agentSpellId.value = agentSpell.value?.id;
    agentGraph.value = agentSpell.value?.versions[0];

    return agentGraph.value;
  };

  const createAgent = async () => {
    await useApi<Spell>('/api/spells', {
      method: 'POST',
      body: {
        workspaceId: workspaceStore.workspaceId,
        creatorId: userStore.user?.id,
        fromSpellId: 'sdragent',
        teamId: workspaceStore.personalTeam?.id,
      },
    });
    await loadAgent();
  };

  const updateConfig = async (updates: Json) => {
    if (!agentGraph.value) return null;

    const updatedInputs = Object.fromEntries(
      Object.entries(agentGraph.value.inputs ?? {}).map(([key, value]) => {
        return [key, updates[key] ? { ...value, value: updates[key] } : value];
      }),
    );

    try {
      await $api<Graph>(`/api/agents/sdr`, {
        method: 'PATCH',
        body: { inputs: updatedInputs, graphId: agentGraph.value.id },
      });
    } catch (error) {
      console.error('An error occurred:', error);
    }
    return agentGraph.value;
  };

  const linkSteps = async () => {
    await $api<Graph>(`/api/agents/sdr/link`, {
      method: 'PATCH',
      body: { graphId: agentGraph.value?.id },
    });
  };

  const loadCampaigns = async () => {
    const { data: runs } = await useApi<GraphRun[]>(
      '/api/agents/sdr/campaigns',
      {
        params: { graphId: agentGraph.value?.id },
      },
    );

    if (runs.value) {
      campaigns.value = runs.value;
    }

    return campaigns.value;
  };

  return {
    agentGraph,
    configState,
    loadAgent,
    linkSteps,
    agentSpellId,
    loadCampaigns,
    hasAccountsLinked,
    campaigns,
  };
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useAgentsStore, import.meta.hot));
}
