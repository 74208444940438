<script setup lang="ts">
import { format } from 'date-fns';
import AppSpellIcon from '~/components/app/AppSpellIcon.vue';
import SpellRunDetails from '~/components/spell/SpellRunDetails.vue';

const spellId = useRouteParams('spellId');
const agentId = useRouteParams('agentId');
const runId = useRouteParams('runId');

const spellStore = useSpellsStore();
const canvasStore = useCanvasStore();
const router = useRouter();
const slideover = useSlideover();
const modal = useModal();
const drawer = ref();

const { inEditor, routeName } = useRouteName();
const { run, runGraph } = storeToRefs(spellStore);
const { trigger: draftTrigger, graph: draftGraph } = storeToRefs(canvasStore);

// History table + test run
await useAsyncData(`run/${runId.value}`, () =>
  spellStore.loadSpellRun(runId.value as string),
);

const trigger = computed(() =>
  inEditor.value && Object.keys(draftTrigger.value ?? {}).length
    ? draftTrigger.value
    : Object.keys(runGraph.value?.trigger ?? {}).length
      ? runGraph.value?.trigger
      : undefined,
);

const inSpellPage = computed(() => !!spellId.value || !!agentId.value);
const isOpen = ref(true);

async function close() {
  if (inEditor.value) {
    slideover.close();
  } else {
    isOpen.value = false;
    setTimeout(async () => {
      const { runId, ...otherParams } = router.currentRoute.value.params;
      await navigateTo({
        name: routeName.value as string,
        params: otherParams,
        replace: true,
      });
    }, 250);
  }
}

const handleNavigate = async () => {
  await navigateTo({
    name: trigger.value ? 'spell.history' : 'spell.run',
    params: { spellId: runGraph.value?.spellId },
  });
  close();
};

onClickOutside(drawer, () => {
  if (!modal.isOpen.value) {
    close();
  }
});

onBeforeUnmount(() => {
  run.value = undefined;
  runGraph.value = undefined;
});
</script>
<template>
  <USlideover
    v-model="isOpen"
    prevent-close
    :overlay="false"
    :ui="{
      base: 'max-h-[85vh] shadow-lg flex flex-col rounded-2xl mr-l mb-auto mt-24',
      width: 'max-w-xl',
    }"
  >
    <UCard
      ref="drawer"
      key="drawer"
      class="h-full w-full overflow-y-scroll"
      :ui="{
        body: {
          padding: 'sm:py-4',
        },
        divide: 'divide-y-reverse divide-gray-200',
        ring: '',
        rounded: 'rounded-2xl',
        header: {
          base: 'flex w-full justify-between sticky top-0 bg-white border-b border-gray-200 z-50',
        },
      }"
    >
      <template #header>
        <span v-if="runId" class="flex gap-2">
          <span v-if="inSpellPage" class="contents">
            <UIcon
              :name="
                agentId ? 'i-respell-sdr-agent' : 'i-ph-clock-counter-clockwise'
              "
              class="text-3xl"
            />
            <p class="title">
              {{ run?.name ?? (agentId ? 'Agent Run' : 'Spell Run') }}
            </p>
          </span>
          <span v-else-if="!!runGraph" class="contents">
            <AppSpellIcon :spell="runGraph.spell" />
            <p class="title truncate">{{ runGraph.spell?.name }}</p>
          </span>

          <p v-if="run?.createdAt" class="subtitle truncate">
            ({{ format(run.createdAt, 'MMM d, HH:mm a') }})
          </p>
        </span>
        <span v-else class="flex gap-2">
          <UIcon name="i-ph-play" class="text-2xl" />
          <p class="title">Test</p>
        </span>

        <UButton
          v-if="!inSpellPage"
          color="primary"
          variant="solid"
          label="Go to spell"
          trailing-icon="i-ph-arrow-right"
          class="ml-auto mr-4"
          @click="handleNavigate"
        />
        <UButton
          :padded="false"
          color="gray"
          variant="link"
          icon="i-ph-x"
          @click="close"
        />
      </template>

      <div v-if="agentId" class="flex flex-col gap-2 mt-m">
        <SpellRunDetails :run="run" :graph="runGraph" size="sm" />
      </div>

      <SpellFormInput
        v-else
        :run="run"
        :graph="inEditor ? draftGraph : runGraph"
        :trigger="trigger"
        size="sm"
        :readonly="!!runId"
      />

      <template v-if="!!run && !agentId" #footer>
        <UFormGroup
          label="Results"
          size="lg"
          description="See what outputs the workflow produced"
          :ui="{
            wrapper: 'w-full',
          }"
        >
          <SpellFormOutput :run="run" :graph="runGraph" size="sm" />
        </UFormGroup>
        <div v-if="!!run" class="flex flex-col gap-2 mt-m">
          <SpellRunDetails :run="run" :graph="runGraph" size="sm" />
        </div>
      </template>
    </UCard>
  </USlideover>
</template>
