import { StepDefinition } from '../../types';

export default {
  key: 'update_crm_companies',
  name: 'Update CRM Companies',
  description: '',
  icon: 'i-ph-address-book',
  category: 'sdr',
  services: ['hubspot'],
  options: {
    value: {
      key: 'value',
      name: 'Company',
      description: 'The company to be updated in the CRM',
      type: 'object',
      isOptional: false,
      listDepth: 0,
      value: null,
    },
    researchContext: {
      key: 'researchContext',
      name: 'Research Context',
      description: 'The context of the research',
      type: 'text/plain',
      isOptional: false,
      listDepth: 0,
      value: null,
    },
  },
  outputs: {
    companyId: {
      key: 'companyId',
      name: 'companyId',
      type: 'text/plain',
      isOptional: false,
      listDepth: 0,
      value: null,
    },
  },
} satisfies StepDefinition;
