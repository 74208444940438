import { Dictionary } from '@respell/utils';
import add_row_to_table from './airtable/add_row_to_table';
import get_airtable_table from './airtable/get_airtable_table';
import create_apollo_account from './apollo/create_apollo_account';
import create_apollo_contact from './apollo/create_apollo_contact';
import create_apollo_opportunity from './apollo/create_apollo_opportunity';
import enrich_apollo_organization from './apollo/enrich_apollo_organization';
import enrich_apollo_person from './apollo/enrich_apollo_person';
import get_apollo_opportunity from './apollo/get_apollo_opportunity';
import search_apollo_organizations from './apollo/search_apollo_organizations';
import search_apollo_people from './apollo/search_apollo_people';
import add_applicant from './ashby/add_applicant';
import add_canididate from './ashby/add_canididate';
import add_note from './ashby/add_note';
import send_call from './bland/send_call';
import add_contact from './coda/add_contact';
import remove_contact from './coda/remove_contact';
import send_campaign from './coda/send_campaign';
import run_code from './customcode/run_code';
import create_upload_link from './dropbox/create_upload_link';
import upload_file from './dropbox/upload_file';
import search from './exa/search';
import read_linkedin_profile from './linkedin/read_linkedin_profile';
import get_transcript from './fireflies/get_transcript';
import search_transcripts from './fireflies/search_transcripts';
import condition from './flow/condition';
import display from './flow/display';
import review from './flow/review';
import start from './flow/start';
import gcp_upload_file from './gcp/upload_file';
import add_label from './gmail/add_label';
import reply_email from './gmail/reply_email';
import send_email from './gmail/send_email';
import create_event from './google-calendar/create_event';
import search_calendar from './google-calendar/search_calendar';
import create_drive_doc from './google-drive/create_drive_doc';
import read_drive_doc from './google-drive/read_drive_doc';
import drive_upload_file from './google-drive/upload_file';
import add_row from './google-sheet/add_row';
import read_sheet from './google-sheet/read_sheet';
import add_applicant_greenhouse from './greenhouse/add_applicant_greenhouse';
import add_canididate_greenhouse from './greenhouse/add_canididate_greenhouse';
import add_note_greenhouse from './greenhouse/add_note_greenhouse';
import add_prospect_greenhouse from './greenhouse/add_prospect_greenhouse';
import schedule_interview_greenhouse from './greenhouse/schedule_interview_greenhouse';
import create_appointment_highlevel from './highlevel/create_appointment_highlevel';
import create_contact_highlevel from './highlevel/create_contact_highlevel';
import create_opportunity_highlevel from './highlevel/create_opportunity_highlevel';
import search_contacts_highlevel from './highlevel/search_contacts_highlevel';
import search_opportunities_highlevel from './highlevel/search_opportunities_highlevel';
import update_contact_highlevel from './highlevel/update_contact_highlevel';
import update_opportunity_highlevel from './highlevel/update_opportunity_highlevel';
import create_company from './hubspot/create_company';
import create_company_note from './hubspot/create_company_note';
import create_contact_hubspot from './hubspot/create_contact_hubspot';
import create_contact_note from './hubspot/create_contact_note';
import create_opportunity_hubspot from './hubspot/create_opportunity_hubspot';
import search_accounts_hubspot from './hubspot/search_accounts_hubspot';
import search_contacts_hubspot from './hubspot/search_contacts_hubspot';
import search_deals from './hubspot/search_deals';
import update_company from './hubspot/update_company';
import update_contact from './hubspot/update_contact';
import anon_comment_instagram_post from './instagram/comment_instagram_post';
import anon_create_instagram_post from './instagram/create_instagram_post';
import anon_get_unread_instagram_dm from './instagram/get_unread_instagram_dm';
import anon_read_instagram_dm from './instagram/read_instagram_dm';
import anon_read_instagram_post from './instagram/read_instagram_post';
import anon_search_instagram from './instagram/search_instagram';
import anon_send_instagram_message from './instagram/send_instagram_message';
import create_comment_linear from './linear/create_comment_linear';
import create_issue_linear from './linear/create_issue_linear';
import get_assigned_issues from './linear/get_assigned_issues';
import get_issue from './linear/get_issue';
import get_open_issues from './linear/get_open_issues';
import search_issues from './linear/search_issues';
import update_issue from './linear/update_issue';
import linkedin_add_comment_to_post from './linkedin/add_comment_to_post';
import linkedin_connect_with_user from './linkedin/connect_with_user';
import linkedin_create_post from './linkedin/create_linkedin_post';
import read_linkedin_dm from './linkedin/read_linkedin_dm';
import related_profiles from './linkedin/related_profiles';
import send_linkedin_dm from './linkedin/send_linkedin_dm';
import add_contact_mailgun from './mailgun/add_contact_mailgun';
import remove_contact_mailgun from './mailgun/remove_contact_mailgun';
import send_campaign_mailgun from './mailgun/send_campaign_mailgun';
import send_mail_mailgun from './mailgun/send_mail_mailgun';
import get_page from './notion/get_page';
import onedrive_create_file from './one-drive/create_file';
import onedrive_read_file from './one-drive/read_file';
import onedrive_search_files from './one-drive/search_files';

import outlook_create_event from './outlook/create_event';
import outlook_search_events from './outlook/search_events';
import outlook_send_email from './outlook/send_email';
import outlook_send_reply from './outlook/send_reply';
import enrich_pdl_organization from './peopledatalabs/enrich_pdl_organization';
import enrich_pdl_person from './peopledatalabs/enrich_pdl_person';
import search_organizations_pdl from './peopledatalabs/search_organizations_pdl';
import search_people_pdl from './peopledatalabs/search_people_pdl';
import create_account from './salesforce/create_account';
import create_contact from './salesforce/create_contact';
import create_lead from './salesforce/create_lead';
import create_opportunity from './salesforce/create_opportunity';
import describe_object from './salesforce/describe_object';
import search_accounts from './salesforce/search_accounts';
import search_contacts from './salesforce/search_contacts';
import search_leads from './salesforce/search_leads';
import search_opportunities from './salesforce/search_opportunities';
import soql_query from './salesforce/soql_query';
import sosl_query from './salesforce/sosl_query';
import update_account from './salesforce/update_account';
import update_contact_salesforce from './salesforce/update_contact_salesforce';
import update_lead from './salesforce/update_lead';
import update_opportunity from './salesforce/update_opportunity';
import add_person_to_hubspot from './sdr/add_person_to_hubspot';
import locate_companies from './sdr/locate_companies';
import prospect_from_company from './sdr/prospect_from_company';
import qualify_company from './sdr/qualify_company';
import update_crm_companies from './sdr/update_crm_companies';
import send_mail from './sendgrid/send_mail';
import single_send from './sendgrid/single_send';
import search_messages from './slack/search_messages';
import send_message from './slack/send_message';
import add_file from './text/add_file';
import add_file_text from './text/add_file_text';
import api from './text/api';
import classify_text from './text/classify_text';
import combine_text from './text/combine_text';
import describe_image from './text/describe_image';
import document_to_text from './text/document_to_text';
import extract_fields from './text/extract_fields';
import generate_image from './text/generate_image';
import generate_text from './text/generate_text';
import get_file from './text/get_file';

import create_article from './intercom/create_article';
import create_contact_intercom from './intercom/create_contact_intercom';
import create_ticket from './intercom/create_ticket';
import reply_conversation from './intercom/reply_conversation';
import reply_ticket from './intercom/reply_ticket';
import search_linkedin_posts from './linkedin/search_linkedin_posts';
import sdr_agent from './sdr/sdr_agent';
import query_database from './text/query_database';
import read_website from './text/read_website';
import research_agent from './text/research_agent';
import search_datasources from './text/search_datasources';
import search_web from './text/search_web';
import summarize_text from './text/summarize_text';
import text_to_document from './text/text_to_document';
import text_to_eml from './text/text_to_eml';
import text_to_speech from './text/text_to_speech';
import transcribe from './text/transcribe';
import { Category, StepDefinition } from './types';
import create_ticket_zendesk from './zendesk/create_ticket_zendesk';
import get_ticket from './zendesk/get_ticket';
import get_ticket_comments from './zendesk/get_ticket_comments';
import list_assigned_tickets from './zendesk/list_assigned_tickets';
import reply_ticket_zendesk from './zendesk/reply_ticket_zendesk';
import search_tickets from './zendesk/search_tickets';
// import create_business from './highlevel/create_business';

const definitions: Dictionary<StepDefinition> = {
  // Flow
  display,
  start,
  condition,
  review,

  // Text
  generate_text,
  extract_fields,
  // Data
  research_agent,
  search_web,
  read_website,
  summarize_text,
  describe_image,
  combine_text,
  api,

  send_call,
  classify_text,
  search_linkedin_posts,

  create_opportunity_highlevel,
  create_contact_highlevel,
  create_appointment_highlevel,

  add_person_to_hubspot,
  locate_companies,
  prospect_from_company,
  qualify_company,
  read_linkedin_profile,
  update_crm_companies,

  update_opportunity_highlevel,
  update_contact_highlevel,

  search_contacts_highlevel,
  search_opportunities_highlevel,

  text_to_eml,
  read_linkedin_dm,
  send_linkedin_dm,
  linkedin_connect_with_user,
  linkedin_add_comment_to_post,
  linkedin_create_post,
  related_profiles,
  // create_business,
  create_drive_doc,
  read_drive_doc,

  // Salesforce
  create_account,
  search_accounts,
  create_opportunity,
  create_contact,
  create_lead,
  update_account,
  update_contact_salesforce,
  update_opportunity,
  update_lead,
  search_leads,
  search_opportunities,
  search_contacts,
  sosl_query,
  soql_query,
  describe_object,

  // Hubspot
  create_company,
  create_contact_hubspot,
  create_opportunity_hubspot,
  create_company_note,
  create_contact_note,
  search_deals,
  search_accounts_hubspot,
  search_contacts_hubspot,
  update_company,
  update_contact,

  // send_envelope_pandadoc,

  // Ashby
  add_applicant,
  add_canididate,
  add_note,
  // schedule_interview,

  // Greenhouse
  add_applicant_greenhouse,
  add_canididate_greenhouse,
  add_note_greenhouse,
  add_prospect_greenhouse,
  schedule_interview_greenhouse,

  // Airtable
  add_row_to_table,
  get_airtable_table,

  // Apollo
  create_apollo_account,
  create_apollo_contact,
  create_apollo_opportunity,
  enrich_apollo_organization,
  enrich_apollo_person,
  get_apollo_opportunity,
  search_apollo_people,
  search_apollo_organizations,

  // Linear
  search_issues,
  get_assigned_issues,
  get_open_issues,
  update_issue,
  get_issue,
  create_issue_linear,
  create_comment_linear,

  sdr_agent,

  // Docusign
  // send_envelope,

  transcribe,
  text_to_document,
  text_to_speech,
  search_datasources,
  query_database,
  get_file,
  add_file,
  add_file_text,
  generate_image,
  document_to_text,

  // Instantly
  add_contact,
  remove_contact,
  send_campaign,

  // Peopledatalabs
  search_people_pdl,
  search_organizations_pdl,
  enrich_pdl_person,
  enrich_pdl_organization,

  // Intercom
  create_article,
  reply_conversation,
  reply_ticket,
  create_ticket,
  create_contact_intercom,

  // Zendesk
  create_ticket_zendesk,
  get_ticket,
  reply_ticket_zendesk,
  get_ticket_comments,
  list_assigned_tickets,
  search_tickets,

  // Notion
  get_page,

  // Exa
  search,

  // Google
  add_row,
  read_sheet,
  search_calendar,
  create_event,
  drive_upload_file,

  // Slack
  search_messages,
  send_message,

  // Dropbox
  create_upload_link,
  upload_file,

  // GCP
  gcp_upload_file,

  // Sendgrid
  send_mail,
  single_send,

  // One Drive
  onedrive_create_file,
  onedrive_read_file,
  onedrive_search_files,

  // Outlook
  outlook_send_email,
  outlook_send_reply,
  outlook_create_event,
  outlook_search_events,

  // Gmail
  send_email,
  reply_email,
  add_label,

  // Instagram
  anon_send_instagram_message,
  anon_read_instagram_dm,
  anon_get_unread_instagram_dm,
  anon_create_instagram_post,
  anon_comment_instagram_post,
  anon_read_instagram_post,
  anon_search_instagram,

  // Fireflies
  search_transcripts,
  get_transcript,

  // Mailchimp
  // send_mail_mailchimp,

  // Mailgun
  send_mail_mailgun,
  add_contact_mailgun,
  remove_contact_mailgun,
  send_campaign_mailgun,

  //Zoominfo
  // search_people_zoom,
  // search_organizations_zoom,
  // enrich_zoom_organization,
  // enrich_zoom_person,

  run_code,
} satisfies Dictionary<StepDefinition>;

export const categories = [
  {
    name: 'Flow',
    key: 'flow',
    icon: 'i-ph-tree-structure-bold',
  },
  {
    name: 'Text',
    key: 'text',
    icon: 'i-ph-text-t-bold',
  },
  {
    name: 'File',
    key: 'file',
    icon: 'i-ph-file-bold',
  },
  {
    name: 'Web & Code',
    key: 'web',
    icon: 'i-ph-globe-simple-bold',
  },
  {
    name: 'Integrations',
    key: 'integrations',
    icon: 'i-ph-puzzle-piece-bold',
  },
] satisfies Category[];

export default definitions;
