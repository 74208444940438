import { StepDefinition } from '../../types';

export default {
  key: 'linkedin_connect_with_user',
  name: 'Connect With User',
  description: 'Connect with a user on linkedin',
  icon: 'i-logos-linkedin-icon',
  category: 'integrations',
  services: ['linkedin'],
  options: {
    name: {
      key: 'name',
      name: 'Name',
      description: "The account's linkedin profile ID.",
      type: 'text/plain',
      isOptional: false,
      metadata: {
        icon: 'i-ph-subtitles-bold',
      },
      listDepth: 0,
      value: null,
    },
    note: {
      key: 'note',
      name: 'Note',
      description: 'An optional note to send with your connection request.',
      type: 'text/plain',
      isOptional: true,
      metadata: {
        icon: 'i-ph-subtitles-bold',
      },
      listDepth: 0,
      value: null,
    },
  },
  outputs: {},
} satisfies StepDefinition;
