<script setup lang="ts">
import type { Json } from '@respell/utils';
import { useChangeCase } from '@vueuse/integrations/useChangeCase.mjs';
import VariablePicker from '~/components/editor/config/VariablePicker.vue';
import AppDropdownInput from './AppDropdownInput.vue';
import AppObjectInput from './AppObjectInput.vue';

const props = defineProps<{
  options: any[] | Json;
  searchable?: boolean;
  creatable?: boolean;
  isSearching?: boolean;
  multiple?: boolean;
  disabled?: boolean;
  placeholder?: string;
  searchablePlaceholder?: string;
  injectable?: boolean;
  variableSchema?: Json;
}>();

const modelValue = defineModel<string | string[] | Json>();
const searchQuery = defineModel<string>('query', {
  required: false,
  default: '',
});

const { emitFormChange } = useFormGroup();

const dropdownOptions = ref(Array.isArray(props.options) ? props.options : []);

// Update dropdownOptions when options hydrate after search
watchDeep(props, (newVal, oldVal) => {
  if (
    ((oldVal.isSearching && !newVal.isSearching) ||
      newVal.options !== !oldVal.options) &&
    Array.isArray(newVal.options)
  ) {
    dropdownOptions.value = newVal.options;
  }
});

const valueAttribute = computed(() => {
  return dropdownOptions.value?.[0]?.id
    ? 'id'
    : dropdownOptions.value[0]?.key
      ? 'key'
      : 'name';
});

const isVariable = computed(() => {
  if (typeof modelValue.value === 'string') {
    const matches = modelValue.value.match(variableRegex);
    return !!matches;
  }
  return false;
});

const isLookup = computed(() => {
  return (
    typeof modelValue.value === 'object' &&
    modelValue.value !== null &&
    !!props.variableSchema
  );
});

onBeforeMount(() => {
  // If the value is a variable, do nothing
  if (isVariable.value) {
    return;
  }

  // Handle multiple selection
  if (props.multiple && modelValue.value?.length) {
    const customOptions = useArrayDifference(
      modelValue.value,
      dropdownOptions.value.map((option) => option[valueAttribute.value]),
    ).value;

    // Add custom options to dropdownOptions
    if (customOptions.length) {
      dropdownOptions.value = [
        ...dropdownOptions.value,
        ...customOptions.map(createNewOption),
      ];
    }
  } else {
    // Handle single selection
    if (
      modelValue.value &&
      !dropdownOptions.value
        .map((option) => option[valueAttribute.value])
        .includes(modelValue.value)
    ) {
      if (props.creatable) {
        // Add new option if creatable
        dropdownOptions.value.push(createNewOption(modelValue.value));
      } else {
        // Set modelValue to null if not creatable
        modelValue.value = null;
      }
    }
  }
});

const selected = computed({
  get: () => (!modelValue.value && props.multiple ? [] : modelValue.value),
  set: (selection) => {
    const { multiple, creatable } = props;
    const { value: options } = dropdownOptions;

    if (multiple) {
      modelValue.value = modelValue.value ?? [];

      const newEntry = modelValue.value?.length
        ? useArrayDifference(selection, modelValue.value).value?.[0]
        : selection?.[0];

      if (newEntry) {
        // Check if the new entry already exists in dropdown options
        if (
          !options?.some((option) => option[valueAttribute.value] === newEntry)
        ) {
          const newOption = createNewOption(newEntry.name);
          dropdownOptions.value.push(newOption);
          modelValue.value = [
            ...modelValue.value,
            newOption[valueAttribute.value],
          ];
          return;
        }
      }
      modelValue.value = selection;
    } else {
      // Handle single selection
      if (modelValue.value === selection) {
        modelValue.value = null;
        return;
      }

      if (
        options?.some((option) => option[valueAttribute.value] === selection)
      ) {
        modelValue.value = selection;
      } else if (creatable) {
        // Create and add new option if creatable
        const newOption = createNewOption(selection.name ?? selection);
        dropdownOptions.value.push(newOption);
        modelValue.value = newOption[valueAttribute.value];
      }
    }
  },
});

const label = computed(() => {
  if (isLookup.value) {
    const usedFields = Object.entries(modelValue.value ?? {})
      .filter(
        ([_, value]) => value !== null && value !== undefined && value !== '',
      )
      .map(([key]) => props.variableSchema[key].name);
    return `Fetching by ${usedFields.join(', ')}`;
  } else if (modelValue.value?.length) {
    return (
      dropdownOptions.value.find(
        (o) => o[valueAttribute.value] === selected.value,
      )?.name ?? modelValue.value
    );
  } else {
    return props.placeholder ?? 'Select an option';
  }
});

const icon = computed(() =>
  isLookup.value ? 'i-ph-sparkle' : modelValue.value ? 'i-ph-check' : null,
);

function createNewOption(name: string) {
  return {
    key: useChangeCase(name, 'camelCase').value,
    id: useChangeCase(name, 'camelCase').value,
    name: useChangeCase(name, 'capitalCase').value,
  };
}
</script>
<template>
  <VariablePicker
    v-if="injectable"
    v-model="modelValue"
    is-range
    :type="variableSchema ? 'lookup' : 'dropdown'"
    class="shrink-0"
    @update:model-value="emitFormChange"
  >
    <template #button="{ incoming }">
      <UButton
        trailing-icon="i-ph-caret-down"
        color="white"
        block
        variant="solid"
        size="lg"
        :ui="{ font: 'font-normal', block: 'justify-between' }"
      >
        <VariableNode v-if="isVariable" v-model="modelValue" size="sm" />
        <span v-else class="flex flex-row items-center gap-2 max-w-full">
          <UIcon v-if="icon" :name="icon" class="w-5 h-5 text-gray-500" />
          <p class="truncate">{{ label }}</p>
        </span>
      </UButton>
    </template>
    <template v-if="variableSchema" #lookup>
      <UAlert
        icon="i-ph-sparkle"
        color="primary"
        variant="soft"
        size="xs"
        title="Look up this record when the spell runs"
        description="Use variables to look up a record by one of the following unique fields when the spell runs."
        class="w-full"
        :ui="{
          title: 'font-bold',
        }"
      />
      <AppObjectInput
        v-model="modelValue"
        :option="{
          key: 'lookup',
          metadata: { schema: props.variableSchema },
        }"
        injectable
        unwrap
        class="z-50"
      />
    </template>
    <template #select>
      <AppDropdownInput
        v-model="selected"
        v-model:query="searchQuery"
        v-bind="props"
        :value-attribute="valueAttribute"
        :dropdown-options="dropdownOptions"
        @update:model-value="emitFormChange"
      />
    </template>
  </VariablePicker>
  <AppDropdownInput
    v-else
    v-model="selected"
    v-model:query="searchQuery"
    v-bind="props"
    :value-attribute="valueAttribute"
    :dropdown-options="dropdownOptions"
    @update:model-value="emitFormChange"
  />
</template>
