import Nango from '@nangohq/frontend';
import type { IntegrationLink } from '@respell/database';
import {
  ValidationError,
  type Dictionary,
  type Json,
  type TemplatedFunction,
} from '@respell/utils';
import { nanoid } from 'nanoid';
import { defineStore } from 'pinia';
export const nango = new Nango({ publicKey: process.env.NANGO_PUBLIC_KEY! });

interface IntegrationStore {
  linkedAccounts: IntegrationLink[];
  isLoading: boolean;
}

export const useIntegrationStore = defineStore('integrations', {
  state: (): IntegrationStore => ({
    linkedAccounts: [],
    isLoading: false,
  }),
  getters: {},
  actions: {
    // Loads all linkeded accounts for this user.
    async loadIntegrations() {
      this.isLoading = true;
      this.linkedAccounts = [];
      const { data: links, error } =
        await useApi<IntegrationLink[]>('/api/integrations');

      if (!links.value || error.value) {
        throw error.value;
        // TODO: https://linear.app/respell/issue/RES-651/handle-empty-responses-from-the-nuxt-api
        // } else if (!links.value) {
        //   console.log('NO links.value');
      } else {
        this.linkedAccounts = links.value;
      }
      this.isLoading = false;
      return this.linkedAccounts;
    },
    async fetchContext({
      options,
      linkedAccountId,
      reference,
      query,
    }: {
      options: Record<string, any>;
      linkedAccountId: string;
      reference: TemplatedFunction;
      query?: string;
    }) {
      try {
        const contextUpdate = await $api<Dictionary<Json>>(
          `/api/integrations/${linkedAccountId}/context`,
          {
            method: 'POST',
            body: {
              reference,
              options: { ...options, query },
            },
          },
        );

        return contextUpdate;
      } catch (error) {
        console.error('Error loading context', error);
      }
      return null;
    },
    // Connects an integration to a user account.
    async connect(provider: string, metadata: any, metadataOverride = {}) {
      // First we generate a new nanoId
      const newId = nanoid();

      const userStore = useUserStore();
      const { user } = storeToRefs(userStore);

      // Handles GCP specific logic
      if (provider === 'gcp') {
        metadata.email = metadata.apiKey.client_email;
        metadata.projectId = metadata.apiKey.project_id;
        metadata.apiKey = metadata.apiKey.private_key;
      }

      const nangoData = {
        credentials: (() => {
          switch (provider) {
            case 'mailgun':
              return { username: 'api', password: metadata.password };
            case 'zendesk':
              return {};
            default:
              return metadata;
          }
        })(),
        params: (() => {
          switch (provider) {
            case 'mailgun':
              return { region: metadata.region };
            case 'gcp':
              return { projectId: metadata.projectId, email: metadata.email };
            default:
              return metadata;
          }
        })(),
      };

      // We then do the nango auth flow.
      // TODO: add better anon support
      if (provider !== 'instagram' && provider !== 'linkedin') {
        await nango.auth(provider, newId, {
          ...nangoData,
          ...metadataOverride,
          detectClosedAuthWindow: true,
        });
      } else {
        await nango.auth(provider, newId, {
          params: { APP_ID: 'none' },
          credentials: { apiKey: metadata.userToken },
        });
      }

      if (user.value?.id) {
        const integrationTest: IntegrationLink = {
          id: newId,
          userId: user.value.id,
          service: provider,
          metadata: {},
          accountId: null,
          teamId: (metadata?.teamId as string) ?? null,
          url:
            provider !== 'zendesk'
              ? (metadata?.url as string) ?? null
              : (metadata?.subdomain as string),
          email: (metadata?.email as string) ?? null,
          createdAt: new Date(),
          updatedAt: new Date(),
        };

        // Finally we hit up the API.
        const { data: updatedAccount, error } = await useApi<any>(
          '/api/integrations',
          {
            method: 'POST',
            body: integrationTest,
          },
        );
        if (error.value) {
          throw new ValidationError({
            key: 'connection_failed',
            message: `${error.value}`,
          });
        }

        // reload the user store
        await refreshNuxtData('integrationLinks');

        return updatedAccount;
      }
    },
    async disconnect(id: string) {
      await useApi('/api/integrations', {
        method: 'DELETE',
        params: {
          id,
        },
      });
      await refreshNuxtData('integrationLinks');
    },
    async savePostAuthData(id: string, data: any) {
      const { data: updatedAccount } = await useApi<IntegrationLink>(
        '/api/integrations',
        {
          method: 'PATCH',
          params: {
            id,
          },
          body: data,
        },
      );
      return updatedAccount;
    },
  },
});
