import type {
  GraphRun,
  Invite,
  Member,
  Team,
  Workspace,
} from '@respell/database';
import { TeamType } from '@respell/database';
import type { Json } from '@respell/utils';
import { PostHogEvents } from '@respell/utils/tracking/types';
import { acceptHMRUpdate, defineStore } from 'pinia';
import type { InviteInput } from '~/server/types';

export const useWorkspaceStore = defineStore('workspaces', {
  state: () => ({
    invites: [] as Invite[],
    isLoading: false,
    team: undefined as Team | undefined,
    teamMembers: [] as Member[],
    teams: [] as Team[],
    todoCount: 0,
    freeActions: undefined as number | undefined,
    monthlyActions: undefined as number | undefined,
    monthlyActionLimit: undefined as number | undefined | null,
    monthlyAddonTasks: undefined as number | undefined | null,
    workspace: undefined as Workspace | undefined,
    workspaceId: undefined as string | undefined,
    workspaceMembers: [] as Member[],
    workspaces: [] as Workspace[],
  }),
  persist: { paths: ['workspaceId'] },
  getters: {
    adminOnlyUploads: (state) => {
      return state.workspace?.adminOnlyUploads;
    },
    piiDetection: (state) => {
      return state.workspace?.piiDetection;
    },
    promptInjectionDetection: (state) => {
      return state.workspace?.promptInjectionDetection;
    },
    subscription: (state) => {
      return state.workspace?.subscriptions?.[0];
    },
    retentionLength: (state) => {
      return state.workspace?.retentionLength;
    },
    subscriptionType(): string {
      return this.subscription?.type || 'free';
    },
    workspaceTeams: (state) => {
      return state.teams.filter((t) => t.workspaceId === state.workspaceId);
    },
    personalTeam: (state) => {
      return state.teams.find(
        (t) =>
          t.type === TeamType.personal && t.workspaceId === state.workspaceId,
      );
    },
    workspaceTeam: (state) => {
      return state.teams.find(
        (t) =>
          t.type === TeamType.workspace && t.workspaceId === state.workspaceId,
      );
    },
  },
  actions: {
    async loadWorkspaces() {
      const { data, error } = await useApi<{
        teams: Team[];
        workspaces: Workspace[];
      }>('/api/workspaces');
      if (error.value || !data.value) {
        console.error('An error occurred:', error.value);
      } else {
        const { teams, workspaces } = data.value;

        this.workspaces = workspaces;
        this.teams = teams;

        this.workspace =
          this.workspaces.find((w) => w.id === this.workspaceId) ??
          this.workspaces[0];

        this.workspaceId = this.workspace?.id;

        await this.fetchInboxPages('todo');
        await this.fetchSpellMeter(this.workspace);
      }

      return this.workspaces;
    },
    async authenticateCarbon() {
      if (this.workspaceId) {
        const { data: token } = await useApi(
          `/api/carbon/${this.workspaceId}/workspace`,
        );

        await initializeCarbonByAccessToken(token.value.token);
      }
    },
    async loadTeam(teamId: string) {
      if (!teamId) return;

      this.team = this.teams.find((t) => t.id === teamId);

      const { data: teamMembers, error } = await useApi<Member[]>(
        `/api/workspaces/${this.workspace?.id}/teams/${teamId}/members`,
      );

      if (error.value || !teamMembers.value) {
        throw new Error(error.value);
      } else {
        this.teamMembers = teamMembers.value;
      }

      return this.team;
    },
    async loadWorkspaceMembers() {
      const { data: members, error } = await useApi(
        `/api/workspaces/${this.workspace?.id}/members`,
      );
      if (error.value || !members.value) {
        console.error('An error occurred:', error.value);
      } else {
        this.workspaceMembers = members.value;
      }

      return this.workspaceMembers;
    },
    async changeWorkspace(workspaceId: string, navigate: boolean = true) {
      const userStore = useUserStore();

      this.workspaceId = workspaceId;
      this.workspace = this.workspaces.find((w) => w.id === workspaceId);

      clearNuxtData('unfinishedSpells');
      await this.fetchInboxPages('todo');
      await this.fetchSpellMeter();
      await refreshNuxtData('workspaceMembers');
      await refreshNuxtData('carbonAuth');

      userStore.identify();

      if (navigate) {
        await navigateTo('/');
      } else {
        await navigateTo('/account/billing', { external: true });
      }

      return this.workspace;
    },
    async assumeWorkspace(workspaceId: string) {
      const workspace = await $api(`/api/workspaces/${workspaceId}/assume`, {
        method: 'POST',
      });

      this.workspaces.push(workspace);
      this.teams.push(...workspace.teams);

      await this.changeWorkspace(workspace.id);
    },
    async createWorkspace(name: string) {
      try {
        const newWorkspace = await $api<Workspace>(`/api/workspaces`, {
          method: 'post',
          body: { name },
        });

        this.workspaces.push(newWorkspace);
        this.teams.push(...newWorkspace.teams);

        await this.changeWorkspace(newWorkspace.id);
      } catch (error) {
        console.error('Failed to create workspace:', error);
      }

      return this.workspace;
    },
    async updateWorkspace(updates: Partial<Workspace>) {
      const updatedWorkspace = await $api<Workspace>(
        `/api/workspaces/${this.workspace?.id}`,
        {
          method: 'PATCH',
          body: updates,
        },
      );
      this.workspace = updatedWorkspace;
      this.workspaces = this.workspaces.map((w) =>
        w.id === this.workspace?.id ? updatedWorkspace : w,
      );

      return this.workspace;
    },
    async loadInvites() {
      const { data: invites, error } = await useApi<Invite[]>(
        `/api/workspaces/${this.workspaceId}/invites`,
      );
      this.invites = invites.value;

      return this.invites;
    },
    async createInvite(inviteInput: InviteInput) {
      const { $clientPosthog } = useNuxtApp();

      await useApi(`/api/invites`, {
        method: 'post',
        body: inviteInput,
      });

      $clientPosthog?.capture(PostHogEvents.UserInvitedTeammate, {
        email: inviteInput.email,
        workspaceId: inviteInput.workspaceId,
        invitedById: inviteInput.invitedById,
        role: inviteInput.role,
      });
    },

    async updateInvite(inviteId: string, updates: object) {
      const { data: invite } = await useApi<Invite>(
        `/api/invites/${inviteId}`,
        {
          method: 'patch',
          body: updates,
        },
      );

      if (invite.value?.status === 'accepted') {
        this.workspaceId = invite.value?.workspaceId;
        await this.loadWorkspaces();
      }

      await refreshNuxtData(`invites/${invite.value?.workspaceId}`);
      await navigateTo('/');

      return invite.value;
    },
    async resendInvite(inviteId: string) {
      await useApi(`/api/invites/${inviteId}/resend`, {
        method: 'post',
      });

      await refreshNuxtData(`invites/${this.workspaceId}`);
    },
    async createTeam(event: FormSubmitEventt) {
      const { data: inputForm } = event;
      const teamInput = { ...inputForm };

      try {
        const team = await $api<Team>(
          `/api/workspaces/${this.workspace?.id}/teams`,
          {
            method: 'post',
            body: teamInput,
          },
        );

        this.teams.push(team);
        this.team = team;

        await navigateTo(`/teams/${team.id}/spells`);
      } catch (error) {
        console.error('Failed to create team:', error);
        return null;
      }
      return this.team;
    },
    async deleteTeam(teamId: string) {
      try {
        await $api<Team>(
          `/api/workspaces/${this.workspace?.id}/teams/${teamId}`,
          {
            method: 'delete',
          },
        );

        this.teams = this.teams.filter((t) => t.id !== teamId);
        await navigateTo('/');
      } catch (error) {
        console.error('Failed to delete team:', error);
      }
      return this.teams;
    },
    async createMembers(newMembers: Member[]) {
      const teamMembers = await $api<Member[]>(
        `/api/workspaces/${this.workspace?.id}/teams/${this.team?.id}/members`,
        {
          method: 'post',
          body: { newMembers },
        },
      );

      this.teamMembers = teamMembers;

      return this.teamMembers;
    },
    // TODO: V2
    // async inviteUsersCsv(workspaceId: string, invitation: WorkspaceUserCsv) {
    //   const sessionToken = useCookie('session-token', {
    //     maxAge: 2592000,
    //   });
    //   const token = sessionToken.value;
    //
    //   await client.post(
    //     `/workspaces/${workspaceId}/users/invite-csv`,
    //     { ...invitation },
    //     {
    //       headers: {
    //         Authorization: `Bearer ${token}`,
    //       },
    //     },
    //   );
    // },
    // TODO: V2
    // async resendInvite(workspaceId: string, workspaceUserId: string) {
    //   const sessionToken = useCookie('session-token', {
    //     maxAge: 2592000,
    //   });
    //   const token = sessionToken.value;
    //
    //   await client.post(
    //     `/workspaces/${workspaceId}/users/${workspaceUserId}/resend`,
    //     {},
    //     {
    //       headers: {
    //         Authorization: `Bearer ${token}`,
    //       },
    //     },
    //   );
    // },
    // async billingDeleteMember(memberId: string) {
    //   let member: Member;
    //
    //   try {
    //     member = await $api(`/api/members/${memberId}`, {
    //       method: 'delete',
    //     });
    //   } catch (error) {
    //     console.error('Failed to delete member:', error);
    //     return { success: false };
    //   } finally {
    //     await refreshNuxtData('workspaceMembers');
    //   }
    //
    //   return member;
    // },
    // async billingUpdateMember(memberId: string, updates: object) {
    //   try {
    //     await $api(`/api/members/${memberId}`, {
    //       method: 'patch',
    //       body: updates,
    //     });
    //   } catch (error) {
    //     console.error('Failed to update member:', error);
    //     return { success: false };
    //   } finally {
    //     await refreshNuxtData('workspaceMembers');
    //   }
    //
    //   return this.members;
    // },
    async deleteMember(memberId: string) {
      try {
        await $api<Team>(
          `/api/workspaces/${this.workspace?.id}/teams/${this.team?.id}/members/${memberId}`,
          {
            method: 'delete',
          },
        );

        this.teamMembers = this.teamMembers.filter((m) => m.id !== memberId);
        this.workspaceMembers = this.workspaceMembers.filter(
          (m) => m.id !== memberId,
        );
      } catch (error) {
        console.error('Failed to delete team:', error);
      }
      return this.teamMembers;
    },
    async updateMember(memberId: string, event: FormSubmitEvent) {
      const { data: inputForm } = event;
      const updates = { ...inputForm };
      try {
        await $api<Member>(
          `/api/workspaces/${this.workspace?.id}/teams/${this.team?.id}/members/${memberId}`,
          {
            method: 'patch',
            body: updates,
          },
        );

        await refreshNuxtData(`workspaceMembers/${this.workspace?.id}`);
      } catch (error) {
        console.error('Failed to update member:', error);
      }

      return this.teamMembers;
    },
    // TODO: V2
    // async getMonthlyUsage(workspaceId: string) {
    //   const sessionToken = useCookie('session-token', {
    //     maxAge: 2592000,
    //   });
    //   const token = sessionToken.value;
    //
    //   const { data } = (
    //     await client.get(`/workspaces/${workspaceId}/usage`, {
    //       headers: {
    //         Authorization: `Bearer ${token}`,
    //       },
    //     })
    //   ).data;
    //   return data;
    // },
    async fetchInboxPages(tabId: 'todo' | 'completed') {
      if (!tabId) return;

      const { data } = await useApi(
        `/api/workspaces/${this.workspaceId}/review/${tabId}`,
      );

      const pages = data.value;

      if (tabId === 'todo') {
        this.todoCount = pages.length;
      }

      return pages ?? [];
    },
    async fetchSpellMeter(workspace?: Workspace) {
      let workspaceForSpellMeter;

      if (workspace) {
        workspaceForSpellMeter = workspace;
      } else {
        if (!this.workspaceId) {
          return null;
        }

        const { data: workspace } = await useApi(
          `/api/workspaces/${this.workspaceId}`,
        );

        workspaceForSpellMeter = workspace.value;
      }

      this.freeActions = workspaceForSpellMeter.freeActions;
      this.monthlyActions = workspaceForSpellMeter.monthlyActions;
      this.monthlyActionLimit = workspaceForSpellMeter.monthlyActionLimit;
      this.monthlyAddonTasks = workspaceForSpellMeter.monthlyAddonTasks;

      return workspaceForSpellMeter;
    },
    async fetchReviewItem(runId: string) {
      try {
        const item = await $api<GraphRun>(
          `/api/workspaces/${this.workspaceId}/review/${runId}`,
        );
        return item;
      } catch (error) {
        console.error('An error occurred:', error);
      }
    },
    async approveReview(runId: string, inputs?: Json) {
      try {
        const updatedRun = await $api<GraphRun>(
          `/api/workspaces/${this.workspaceId}/review/${runId}/approve`,
          { method: 'POST', body: { inputs } },
        );
        return updatedRun;
      } catch (error) {
        console.error('An error occurred:', error);
      }
    },
    async rejectReview(runId: string) {
      try {
        await $api(
          `/api/workspaces/${this.workspaceId}/review/${runId}/reject`,
          {
            method: 'POST',
          },
        );
      } catch (error) {
        console.error('An error occurred:', error);
      }
    },
  },
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useWorkspaceStore, import.meta.hot));
}
