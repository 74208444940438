import { StepDefinition } from '../../types';

export default {
  key: 'anon_create_instagram_post',
  name: 'Create Instagram Post',
  description: 'Creates an image post on Instagram.',
  icon: 'i-skill-icons-instagram',
  category: 'integrations',
  services: ['instagram'],
  options: {
    file: {
      key: 'file',
      name: 'Image',
      description: 'The image you would like to post.',
      type: 'file/image',
      isOptional: false,
      metadata: {
        icon: 'i-ph-subtitles-bold',
      },
      listDepth: 0,
      value: null,
    },
    description: {
      key: 'description',
      name: 'The description of the instagram post',
      description: 'The content of the instagram post',
      type: 'text/plain',
      isOptional: true,
      listDepth: 0,
      metadata: {},
      value: null,
    },
  },
  outputs: {},
} satisfies StepDefinition;
