import { StepDefinition } from '../../types';

export default {
  key: 'anon_send_instagram_message',
  name: 'Send Instagram Message',
  description: 'Sends a message to another user on Instagram.',
  icon: 'i-skill-icons-instagram',
  category: 'integrations',
  services: ['instagram'],
  options: {
    name: {
      key: 'name',
      name: 'Name',
      description: "The account's name.",
      type: 'text/plain',
      isOptional: false,
      metadata: {
        icon: 'i-ph-subtitles-bold',
      },
      listDepth: 0,
      value: null,
    },
    content: {
      key: 'content',
      name: 'Message content',
      description: 'The content of the message',
      type: 'text/plain',
      isOptional: true,
      listDepth: 0,
      metadata: {},
      value: null,
    },
  },
  outputs: {},
} satisfies StepDefinition;
