import { StepDefinition } from '../../types';

export default {
  key: 'read_linkedin_dm',
  name: 'Read Linkedin DM',
  description: 'Reads the last 40 direct messages from a LinkedIn account.',
  icon: 'i-logos-linkedin-icon',
  category: 'integrations',
  services: ['linkedin'],
  options: {
    name: {
      key: 'name',
      name: 'Name',
      description: "The account's linkedin profile ID.",
      type: 'text/plain',
      isOptional: false,
      metadata: {
        icon: 'i-ph-subtitles-bold',
      },
      listDepth: 0,
      value: null,
    },
  },
  outputs: {
    messages: {
      key: 'messages',
      name: 'Chat History',
      isOptional: false,
      type: 'text/plain',
      listDepth: 0,
      value: null,
    },
  },
} satisfies StepDefinition;
