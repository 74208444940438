import { StepDefinition } from '../../types';

export default {
  key: 'qualify_company',
  name: 'Qualify Company',
  description: '',
  icon: 'i-ph-seal-check',
  category: 'sdr',
  services: [],
  options: {
    value: {
      key: 'value',
      name: 'Company',
      description: 'The company to be qualified',
      type: 'object',
      isOptional: false,
      listDepth: 0,
      value: null,
    },
    companyDetails: {
      key: 'companyDetails',
      name: 'Company Details',
      description: 'The details of the company',
      type: 'text/plain',
      isOptional: false,
      listDepth: 0,
      value: null,
    },
    companyProspectingGoal: {
      key: 'companyProspectingGoal',
      name: 'Company Prospecting Goal',
      description: 'The goal of the prospecting activity for the company',
      type: 'text/plain',
      isOptional: false,
      listDepth: 0,
      value: null,
    },
  },
  outputs: {
    company: {
      key: 'company',
      name: 'Company',
      description: '',
      type: 'object',
      isOptional: false,
      listDepth: 0,
      value: {},
    },
    justification: {
      key: 'justification',
      name: 'Justification',
      description: '',
      type: 'text/plain',
      isOptional: false,
      listDepth: 0,
      value: null,
    },
    summary: {
      key: 'summary',
      name: 'Summary',
      description: '',
      type: 'text/plain',
      isOptional: false,
      listDepth: 0,
      value: null,
    },
  },
} satisfies StepDefinition;
