import { StepDefinition } from '../../types';

export default {
  key: 'prospect_from_company',
  name: 'Prospect from Company',
  description: '',
  icon: 'i-ph-users-three',
  category: 'sdr',
  services: ['hubspot'],
  options: {
    value: {
      key: 'value',
      name: 'Company',
      type: 'object',
      isOptional: false,
      listDepth: 0,
      metadata: {
        icon: 'i-ph-info',
      },
      value: null,
    },
    prospectingGoal: {
      key: 'prospectingGoal',
      name: 'Prospecting Goal',
      description: 'The goal of the prospecting activity',
      type: 'text/plain',
      isOptional: false,
      listDepth: 0,
      metadata: {
        icon: 'i-ph-target',
      },
      value: null,
    },
    person_locations: {
      key: 'person_locations',
      name: 'Person Locations',
      description: 'The locations of the persons to be prospected',
      type: 'text/plain',
      isOptional: true,
      listDepth: 1,
      metadata: {
        icon: 'i-ph-map-pin',
      },
      value: null,
    },
    person_seniorities: {
      key: 'person_seniorities',
      name: 'Person Seniorities',
      description: 'The seniorities of the persons to be prospected',
      type: 'text/plain',
      isOptional: true,
      listDepth: 1,
      metadata: {
        icon: 'i-ph-briefcase',
      },
      value: null,
    },
    person_titles: {
      key: 'person_titles',
      name: 'Person Titles',
      description: 'The titles of the persons to be prospected',
      type: 'text/plain',
      isOptional: true,
      listDepth: 1,
      metadata: {
        icon: 'i-ph-id-badge',
      },
      value: null,
    },

  },
  outputs: {
    person: {
      key: 'person',
      name: 'person',
      description: '',
      type: 'object',
      isOptional: false,
      listDepth: 0,
      metadata: {
        icon: 'i-ph-info',
        schema: {
          details: {
            key: 'details',
            name: 'details',
            description: '',
            type: 'object',
            isOptional: false,
            listDepth: 0,
            metadata: {
              icon: 'i-ph-info',
            },
            value: null,
          },
          justification: {
            key: 'justification',
            name: 'justification',
            description: '',
            type: 'text/plain',
            isOptional: false,
            listDepth: 0,
            metadata: {
              icon: 'i-ph-info',
            },
            value: null,
          },
          webContext: {
            key: 'webContext',
            name: 'webContext',
            description: '',
            type: 'text/plain',
            isOptional: false,
            listDepth: 0,
            metadata: {
              icon: 'i-ph-info',
            },
            value: null,
          },
        },
      },
      value: null,
    },
  },
} satisfies StepDefinition;
