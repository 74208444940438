<script setup lang="ts">
import SpellRunDrawer from '~/components/modals/SpellRunDrawer.vue';

useHead({
  title: 'Respell',
  link: [{ rel: 'icon', type: 'image/x-icon', href: '/favicon.ico' }],
});

const runId = useRouteParams('runId');
const showMobileBanner = ref(true);

const userStore = useUserStore();
const workspaceStore = useWorkspaceStore();
const spellStore = useSpellsStore();
const integrationStore = useIntegrationStore();

const { status } = useAuth();
const { workspace } = storeToRefs(workspaceStore);
const { width: screenWidth } = useWindowSize();

// USER + WORKSPACES
await useAsyncData('user', () => userStore.loadUser(), {
  watch: [status],
  immediate: status.value === 'authenticated',
});

await useAsyncData('workspaces', () => workspaceStore.loadWorkspaces(), {
  watch: [status],
  immediate: status.value === 'authenticated',
});

// LAZY LOADS
await useAsyncData('carbonAuth', () => workspaceStore.authenticateCarbon(), {
  watch: [status, workspace],
  lazy: true,
  immediate: status.value === 'authenticated' && !!workspace.value,
});

await useAsyncData('savedSpells', () => spellStore.loadSavedSpells(), {
  watch: [status],
  lazy: true,
  immediate: status.value === 'authenticated',
});

await useAsyncData('relevantTags', () => spellStore.loadRelevantTags(), {
  watch: [status],
  lazy: true,
  immediate: status.value === 'authenticated',
});

await useAsyncData(
  'integrationLinks',
  () => integrationStore.loadIntegrations(),
  {
    watch: [status],
    lazy: true,
    immediate: status.value === 'authenticated',
  },
);

await workspaceStore.fetchInboxPages('todo');

onBeforeMount(() => {
  watch(
    status,
    () => {
      userStore.identify();
    },
    { immediate: status.value === 'authenticated' },
  );
});
</script>
<template>
  <NuxtLayout>
    <UAlert
      v-if="showMobileBanner && screenWidth < 400"
      icon="i-ph-warning-circle"
      color="red"
      size="xl"
      variant="solid"
      title="Please note that Respell is not yet optimized for mobile devices."
      :close-button="{
        icon: 'i-ph-x',
        color: 'white',
        variant: 'link',
        padded: false,
      }"
      :ui="{
        title: 'font-bold',
      }"
      class="absolute inset-x-0 w-full top-0 z-[55]"
      @close="showMobileBanner = false"
    />
    <NuxtPage />
    <SpellRunDrawer v-if="!!runId" />
  </NuxtLayout>
  <UModals />
  <USlideovers />
  <UNotifications />
</template>
<style lang="scss">
@import '@vue-flow/core/dist/style.css';
@import '@vue-flow/core/dist/theme-default.css';

html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
  background-color: color('bg', 'page');
}

body {
  display: flex;
  flex-direction: column;
}

#__nuxt {
  flex: 1;
  display: flex;
}

html.scroll::-webkit-scrollbar,
body.scroll::-webkit-scrollbar {
  display: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
  appearance: textfield;
}

p {
  margin: 0;
}

img {
  -webkit-touch-callout: none; /* iOS Safari */
  user-select: none; /* Safari */
  user-select: none; /* Konqueror HTML */
  user-select: none; /* Old versions of Firefox */
  user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}

.expand-enter-active,
.expand-leave-active {
  transition-property: opacity, height;
}

.expand-enter,
.expand-leave-to {
  opacity: 0;
}
</style>
