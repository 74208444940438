import { StepDefinition } from '../../types';

export default {
  key: 'anon_search_instagram',
  name: 'Search Instagram',
  description: 'Performs a search on Instagram',
  icon: 'i-skill-icons-instagram',
  category: 'integrations',
  services: ['instagram'],
  options: {
    search: {
      key: 'search',
      name: 'Search',
      description: 'The query that you would like to search instagram for',
      type: 'text/plain',
      isOptional: false,
      listDepth: 0,
      value: null,
    },
  },
  outputs: {
    metadata: {
      key: 'metadata',
      name: 'Posts Metadata',
      description: 'object',
      isOptional: false,
      type: 'object',
      listDepth: 1,
      value: null,
    },
  },
} satisfies StepDefinition;
