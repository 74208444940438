import { StepDefinition } from '../../types';

export default {
  key: 'add_person_to_hubspot',
  name: 'Add Lead to HubSpot',
  description: '',
  icon: 'i-ph-rows-plus-top',
  category: 'sdr',
  services: ['hubspot'],
  options: {
    value: {
      key: 'value',
      name: 'Person',
      description: 'The person to be added to HubSpot',
      type: 'object',
      isOptional: false,
      listDepth: 0,
      metadata: {
        icon: 'i-ph-user',
      },
      value: null,
    },
    values: {
      key: 'values',
      name: 'Persons',
      description: 'The person to be added to HubSpot',
      type: 'object',
      isOptional: true,
      listDepth: 0,
      metadata: {
        icon: 'i-ph-user',
      },
      value: null,
    },
    companyId: {
      key: 'companyId',
      name: 'Company ID',
      description: 'The ID of the company',
      type: 'text/plain',
      isOptional: false,
      listDepth: 0,
      value: null,
    },
    company: {
      key: 'company',
      name: 'Company',
      description: 'The company of the person',
      type: 'object',
      isOptional: false,
      listDepth: 0,
      metadata: {
        icon: 'i-ph-building',
      },
      value: null,
    },
    prospectingGoal: {
      key: 'prospectingGoal',
      name: 'Prospecting Goal',
      description: 'The goal of prospecting',
      type: 'text/plain',
      isOptional: false,
      listDepth: 0,
      metadata: {
        icon: 'i-ph-target',
      },
      value: null,
    },
    emailTemplate: {
      key: 'emailTemplate',
      name: 'Email Template',
      description: 'The template for the email to be sent',
      type: 'text/plain',
      isOptional: true,
      listDepth: 0,
      metadata: {
        icon: 'i-ph-template',
      },
      value: null,
    },
    notesAboutTemplate: {
      key: 'notesAboutTemplate',
      name: 'Notes About Template',
      description: 'Additional notes about the email template',
      type: 'text/plain',
      isOptional: true,
      listDepth: 0,
      metadata: {
        icon: 'i-ph-note',
      },
      value: null,
    },
    companyName: {
      key: 'companyName',
      name: 'Company Name',
      description: 'The name of the company',
      type: 'text/plain',
      isOptional: true,
      listDepth: 0,
      metadata: {
        icon: 'i-ph-building',
      },
      value: null,
    },
    companyDescription: {
      key: 'companyDescription',
      name: 'Company Description',
      description: 'A description of the company',
      type: 'text/plain',
      isOptional: true,
      listDepth: 0,
      metadata: {
        icon: 'i-ph-file-text',
      },
      value: null,
    },
    companyWebsite: {
      key: 'companyWebsite',
      name: 'Company Website',
      description: 'The website of the company',
      type: 'text/plain',
      isOptional: true,
      listDepth: 0,
      metadata: {
        icon: 'i-ph-globe',
      },
      value: null,
    },
    productOrService: {
      key: 'productOrService',
      name: 'Product or Service',
      description: 'The product or service offered by the company',
      type: 'text/plain',
      isOptional: true,
      listDepth: 0,
      metadata: {
        icon: 'i-ph-box',
      },
      value: null,
    },
    templateSubject: {
      key: 'templateSubject',
      name: 'Template Subject',
      description: 'The subject of the email template',
      type: 'text/plain',
      isOptional: true,
      listDepth: 0,
      metadata: {
        icon: 'i-ph-email',
      },
      value: null,
    },
    otherCompanyDetails: {
      key: 'otherCompanyDetails',
      name: 'Other Company Details',
      description: 'Additional details about the company',
      type: 'text/plain',
      isOptional: true,
      listDepth: 1,
      metadata: {
        icon: 'i-ph-info',
      },
      value: null,
    },
  },
  outputs: {
    hubspotObjectId: {
      key: 'hubspotObjectId',
      name: 'HubSpot Object ID',
      description: 'The ID of the HubSpot object',
      type: 'text/plain',
      isOptional: false,
      listDepth: 0,
      metadata: {
        icon: 'i-ph-id-card',
      },
      value: null,
    },
    emailBody: {
      key: 'emailBody',
      name: 'Email Body',
      description: 'The email body',
      type: 'text/plain',
      isOptional: false,
      listDepth: 0,
      metadata: {
        icon: 'i-ph-email',
      },
      value: null,
    },
    email: {
      key: 'email',
      name: 'Email',
      description: 'The email address',
      type: 'text/plain',
      isOptional: false,
      listDepth: 0,
      metadata: {
        icon: 'i-ph-email',
      },
      value: null,
    },
    emailSubject: {
      key: 'emailSubject',
      name: 'Email Subject',
      description: 'The email subject',
      type: 'text/plain',
      isOptional: false,
      listDepth: 0,
      metadata: {
        icon: 'i-ph-email',
      },
      value: null,
    },
  },
} satisfies StepDefinition;
