export type PostHogFeatureFlags =
  | 'access-to-google-integrations'
  | 'is-enterprise'
  | 'integrations'
  | 'nightly'
  | 'triggers'
  | 'steps'
  | 'step-options';

export enum PostHogEventTraits {
  Cancelled = 'cancelled',
  Completed = 'completed',
  EditorEntered = 'editor-entered',
  Failed = 'failed',
  Started = 'started',
  Succeeded = 'succeeded',
  Added = 'added',
  Removed = 'removed',
  Updated = 'updated',
  Enabled = 'enabled',
  Disabled = 'disabled',
}

export enum PostHogEvents {
  SpellTriggerEnabled = 'spell-trigger-enabled',
  UserSignedUp = 'user-signed-up',
  UserLoggedIn = 'user-logged-in',
  SpellTest = 'spell-test',
  UserCreatedSpell = 'user-created-spell',
  UserDuplicatedSpell = 'user-duplicated-spell',
  UserPublishedSpell = 'user-published-spell',
  SpellRunStarted = 'spell-run-started',
  UserChangedPlan = 'user-changed-plan',
  UserInvitedTeammate = 'user-invited-teammate',
  UserCompletedTutorial = 'user-completed-tutorial',
  UserUsedTemplate = 'user-used-template',
  UserCompletedOnboarding = 'user-completed-onboarding',
  SpellRunSucceeded = 'spell-run-succeeded',
  SpellRunFailed = 'spell-run-failed',
  UserSharedSpell = 'user-shared-spell',
  UserPaidSubscription = 'user-paid-subscription',
  UserScheduledSpell = 'user-scheduled-spell',
  UserTutorial = 'user-tutorial',
  AgentCampaignStarted = 'agent-campaign-started',
}

export enum ClickEvents {
  Dismissed = 'dismissed',
  Accepted = 'accepted',
  Clicked = 'clicked',
  DoubleClicked = 'double-clicked',
  RightClicked = 'right-clicked',
}

export enum PageNames {
  Editor = 'editor',
  Home = 'home',
  Pricing = 'pricing',
}

export enum SpellRunTypes {
  UI = 'ui',
  API = 'api',
  zapier = 'zapier',
  bulk = 'bulk',
  trigger = 'trigger',
  schedule = 'schedule',
}

export enum SpellCreatedTypes {
  // V2 Types
  Manual = 'manual',
  Trigger = 'trigger',
  // V1 Types
  Scratch = 'scratch',
  Template = 'template',
  // TBC
  Spellcaster = 'spellcaster',
}

export enum ChatResponseTypes {
  Normal = 'normal',
  Agent = 'agent',
}

export enum PlanEventType {
  Upgrade = 'upgrade',
  Downgrade = 'downgrade',
}
