import { StepDefinition } from '../../types';

export default {
  key: 'anon_get_unread_instagram_dm',
  name: 'Get Unread Instagram DMs',
  description: 'Gets all unread direct messages from your Instagram account.',
  icon: 'i-skill-icons-instagram',
  category: 'integrations',
  services: ['instagram'],
  options: {},
  outputs: {
    messages: {
      key: 'messages',
      name: 'Chat History',
      description: 'object',
      isOptional: false,
      type: 'object',
      listDepth: 1,
      value: null,
    },
  },
} satisfies StepDefinition;
