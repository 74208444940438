import revive_payload_client_CdfvqAQIdx from "/home/runner/work/respell/respell/node_modules/.pnpm/nuxt@3.11.2_@azure+storage-blob@12.17.0_encoding@0.1.13__@parcel+watcher@2.4.1_@types+node@20_ixqpl4ckrskevyzi4y7de5acxq/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_RmO6hEvB2W from "/home/runner/work/respell/respell/node_modules/.pnpm/nuxt@3.11.2_@azure+storage-blob@12.17.0_encoding@0.1.13__@parcel+watcher@2.4.1_@types+node@20_ixqpl4ckrskevyzi4y7de5acxq/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_qrfTD6d3fC from "/home/runner/work/respell/respell/node_modules/.pnpm/nuxt@3.11.2_@azure+storage-blob@12.17.0_encoding@0.1.13__@parcel+watcher@2.4.1_@types+node@20_ixqpl4ckrskevyzi4y7de5acxq/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import posthog_client_WalKbtYVol from "/home/runner/work/respell/respell/node_modules/.pnpm/nuxt-posthog@1.5.3_magicast@0.3.3_rollup@4.14.2_webpack-sources@3.2.3/node_modules/nuxt-posthog/dist/runtime/plugins/posthog.client.js";
import payload_client_2ouJfH166t from "/home/runner/work/respell/respell/node_modules/.pnpm/nuxt@3.11.2_@azure+storage-blob@12.17.0_encoding@0.1.13__@parcel+watcher@2.4.1_@types+node@20_ixqpl4ckrskevyzi4y7de5acxq/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_EJtQX73YSN from "/home/runner/work/respell/respell/node_modules/.pnpm/nuxt@3.11.2_@azure+storage-blob@12.17.0_encoding@0.1.13__@parcel+watcher@2.4.1_@types+node@20_ixqpl4ckrskevyzi4y7de5acxq/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_iFptFGwtho from "/home/runner/work/respell/respell/node_modules/.pnpm/@pinia+nuxt@0.5.1_rollup@4.14.2_typescript@5.4.3_vue@3.4.21_typescript@5.4.3_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/home/runner/work/respell/respell/apps/nuxt/.nuxt/components.plugin.mjs";
import prefetch_client_vldNCjHXDY from "/home/runner/work/respell/respell/node_modules/.pnpm/nuxt@3.11.2_@azure+storage-blob@12.17.0_encoding@0.1.13__@parcel+watcher@2.4.1_@types+node@20_ixqpl4ckrskevyzi4y7de5acxq/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import directives_8rOPWRrlsh from "/home/runner/work/respell/respell/node_modules/.pnpm/nuxt-posthog@1.5.3_magicast@0.3.3_rollup@4.14.2_webpack-sources@3.2.3/node_modules/nuxt-posthog/dist/runtime/plugins/directives.js";
import plugin_AZQWBL2Pec from "/home/runner/work/respell/respell/node_modules/.pnpm/nuxt-csurf@1.6.1_magicast@0.3.3_rollup@4.14.2/node_modules/nuxt-csurf/dist/runtime/plugin.mjs";
import slideovers_T3h6f6eJ0T from "/home/runner/work/respell/respell/node_modules/.pnpm/@nuxt+ui@2.18.2_axios@1.6.5_change-case@4.1.2_focus-trap@7.5.4_magicast@0.3.3_rollup@4.14.2_s_77c6jmsz23v65memj7qb7krvnq/node_modules/@nuxt/ui/dist/runtime/plugins/slideovers.js";
import modals_1XeGz8BZWV from "/home/runner/work/respell/respell/node_modules/.pnpm/@nuxt+ui@2.18.2_axios@1.6.5_change-case@4.1.2_focus-trap@7.5.4_magicast@0.3.3_rollup@4.14.2_s_77c6jmsz23v65memj7qb7krvnq/node_modules/@nuxt/ui/dist/runtime/plugins/modals.js";
import colors_l8GZ76YVK9 from "/home/runner/work/respell/respell/node_modules/.pnpm/@nuxt+ui@2.18.2_axios@1.6.5_change-case@4.1.2_focus-trap@7.5.4_magicast@0.3.3_rollup@4.14.2_s_77c6jmsz23v65memj7qb7krvnq/node_modules/@nuxt/ui/dist/runtime/plugins/colors.js";
import plugin_client_YRiAHz8vZg from "/home/runner/work/respell/respell/node_modules/.pnpm/@nuxtjs+color-mode@3.4.2_magicast@0.3.3_rollup@4.14.2/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_cF7FLZlTle from "/home/runner/work/respell/respell/node_modules/.pnpm/@nuxt+icon@1.3.1_magicast@0.3.3_rollup@4.14.2_vite@5.2.2_@types+node@20.12.7_sass@1.75.0_ters_zcvzixaoej7gszulyg3knh2vma/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import plugin_cslGCHgyVI from "/home/runner/work/respell/respell/node_modules/.pnpm/@sidebase+nuxt-auth@0.7.2_@azure+storage-blob@12.17.0_encoding@0.1.13__encoding@0.1.13_next-a_lj2h6dwoxb4b5ymznfdr7cf5jq/node_modules/@sidebase/nuxt-auth/dist/runtime/plugin.mjs";
import plugin_client_qpwKTUfgia from "/home/runner/work/respell/respell/node_modules/.pnpm/nuxt-gtag@0.5.7_rollup@4.14.2/node_modules/nuxt-gtag/dist/runtime/plugin.client.mjs";
import chunk_reload_client_2kj2CnxPVC from "/home/runner/work/respell/respell/node_modules/.pnpm/nuxt@3.11.2_@azure+storage-blob@12.17.0_encoding@0.1.13__@parcel+watcher@2.4.1_@types+node@20_ixqpl4ckrskevyzi4y7de5acxq/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_1BdlnigI0n from "/home/runner/work/respell/respell/node_modules/.pnpm/@pinia-plugin-persistedstate+nuxt@1.2.0_@pinia+nuxt@0.5.1_rollup@4.14.2_typescript@5.4.3_vue@_3s4eqfugtrvrqugtjirs3wfega/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.mjs";
import filepond_3qnHRCNugt from "/home/runner/work/respell/respell/apps/nuxt/src/plugins/filepond.ts";
import loadGoogleAuthScripts_OqUG037DcI from "/home/runner/work/respell/respell/apps/nuxt/src/plugins/loadGoogleAuthScripts.ts";
import authUnthread_client_wQjIaLV3NI from "/home/runner/work/respell/respell/apps/nuxt/src/plugins/authUnthread.client.ts";
import casl_D1YIVgqED7 from "/home/runner/work/respell/respell/apps/nuxt/src/plugins/casl.ts";
import sentry_client_KAXFuL2wum from "/home/runner/work/respell/respell/apps/nuxt/src/plugins/sentry.client.ts";
export default [
  revive_payload_client_CdfvqAQIdx,
  unhead_RmO6hEvB2W,
  router_qrfTD6d3fC,
  posthog_client_WalKbtYVol,
  payload_client_2ouJfH166t,
  check_outdated_build_client_EJtQX73YSN,
  plugin_vue3_iFptFGwtho,
  components_plugin_KR1HBZs4kY,
  prefetch_client_vldNCjHXDY,
  directives_8rOPWRrlsh,
  plugin_AZQWBL2Pec,
  slideovers_T3h6f6eJ0T,
  modals_1XeGz8BZWV,
  colors_l8GZ76YVK9,
  plugin_client_YRiAHz8vZg,
  plugin_cF7FLZlTle,
  plugin_cslGCHgyVI,
  plugin_client_qpwKTUfgia,
  chunk_reload_client_2kj2CnxPVC,
  plugin_1BdlnigI0n,
  filepond_3qnHRCNugt,
  loadGoogleAuthScripts_OqUG037DcI,
  authUnthread_client_wQjIaLV3NI,
  casl_D1YIVgqED7,
  sentry_client_KAXFuL2wum
]