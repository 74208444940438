import { createId } from '@paralleldrive/cuid2';
import {
  GraphType,
  RunState,
  ScheduleState,
  type AccessLevel,
  type Graph as BaseGraph,
  type Spell as BaseSpell,
  type Collaborator,
  type Edge,
  type GraphRun,
  type IntegrationLink,
  type PermissionWithTeam,
  type RunGroup,
  type Schedule,
  type Step,
  type StepRun,
  type TemplateMetadata,
  type Trigger,
} from '@respell/database';
import definitions from '@respell/steps';
import type { Json } from '@respell/utils';
import { PostHogEvents } from '@respell/utils/tracking/types';
import { acceptHMRUpdate, defineStore, storeToRefs } from 'pinia';
import { relevantTagsMap } from '~/util/categories';
import { useUserStore } from './users';
import { useWorkspaceStore } from './workspaces';

export type Template = TemplateMetadata & { spell: Spell; preview: GraphRun };

export type Spell = BaseSpell & {
  templateMetadata: Template;
};

export type StepWithLinks = Step & { integrations: IntegrationLink[] };

export type Graph = BaseGraph & {
  steps: StepWithLinks[];
  edges: Edge[];
  inputs: JSON;
  outputs: JSON;
  trigger?: Trigger;
  schedule?: Schedule;
};

export const useSpellsStore = defineStore('spells', {
  state: () => ({
    collaborators: [] as Collaborator[],
    bulkGroups: [] as RunGroup[],
    draftGraph: undefined as Graph | undefined,
    groupRuns: [] as GraphRun[],
    groupRunCount: 0,
    isConnected: false,
    isRunning: false,
    isSaving: false,
    isSearching: false,
    isTesting: false,
    inSlack: false,
    liveGraph: undefined as Graph | undefined,
    nextRunId: createId(),
    permissions: [] as PermissionWithTeam[],
    recentSpellIds: [] as string[],
    recentSpells: [] as Spell[],
    relevantTags: [] as string[],
    run: undefined as GraphRun | undefined,
    runGraph: undefined as Graph | undefined,
    savedAt: undefined as Date | undefined,
    savedSpells: [] as Spell[],
    templateResults: [] as Template[],
    schedules: [] as Schedule[],
    sharedSpells: [] as Spell[],
    spell: undefined as Spell | undefined,
    suggestedTags: [] as string[],
    teamSpells: [] as Spell[],
    testRun: undefined as GraphRun | undefined,
    transport: null as string | null,
    trigger: undefined as Trigger | undefined,
    versions: [] as Graph[],
    workspaceSpells: [] as Spell[],
  }),
  persist: { paths: ['recentSpellIds'] },
  getters: {
    isSaved(state) {
      return (spellId: string) =>
        state.savedSpells.find((saved) => saved.id === spellId);
    },
    accessLevel(state) {
      const userStore = useUserStore();
      const workspaceStore = useWorkspaceStore();
      const { user } = storeToRefs(userStore);
      const { workspaces } = storeToRefs(workspaceStore);

      // Check if user is collaborator
      const spellCollaborator = state.collaborators.find(
        (collaborator) =>
          !collaborator.isWorkspace &&
          collaborator.accessorId === user.value?.id,
      );
      // Check if the user is the owner of the spell
      if (state.spell?.creatorId === user.value?.id) {
        return 'owner';
      } else if (spellCollaborator) {
        return spellCollaborator.role;
      }

      const workspacesIds = workspaces.value.map((workspace) => workspace.id);
      const workspaceCollaborator = state.collaborators.find(
        (collaborator) =>
          collaborator.isWorkspace &&
          workspacesIds.includes(collaborator.accessorId),
      );

      // Check if the user belongs to a workspace that the spell is in
      if (workspaceCollaborator) {
        return workspaceCollaborator.role;
      }

      // Lastly, check if the spell is public
      if (state.spell?.isPublic) {
        return 'viewer';
      } else {
        return null;
      }
    },
    template(state) {
      return state.spell?.templateMetadata;
    },
    runTime(state) {
      if (state.run?.endedAt) {
        return state.run?.endedAt - state.run?.createdAt;
      }
    },
    hasRun(state) {
      return state.run?.state === 'success' || state.run?.state === 'error';
    },
    liveIntegrations(state) {
      return Array.from(
        new Set(
          state.liveGraph?.steps.flatMap(
            (step) => definitions[step.key].services,
          ),
        ),
      );
    },
    draftIntegrations(state) {
      return Array.from(
        new Set(
          state.draftGraph?.steps.flatMap(
            (step) => definitions[step.key].services,
          ),
        ),
      );
    },
  },
  actions: {
    async loadCompleteSpell(spellId: string) {
      if (!spellId) {
        return null;
      }

      const canvasStore = useCanvasStore();
      this.resetSpellDetails();

      const { data: spell, error } = await useCache<Spell, Error>(
        `/api/spells/${spellId}`,
        { key: `spell/${spellId}` },
      );
      if (error.value || !spell.value) {
        // await navigateTo('/')
        throw new Error(error.value);
      } else {
        this.spell = spell.value;
        this.liveGraph = this.spell?.versions?.find(
          (g) => g.type === GraphType.live,
        );
        this.draftGraph = this.spell?.versions?.find(
          (g) => g.type === GraphType.draft,
        );
        this.trigger = this.liveGraph?.trigger;
        canvasStore.$reset();
      }
      return this.spell;
    },
    async loadSpellRun(runId?: string) {
      this.run = undefined;
      this.runGraph = undefined;

      if (!runId) return null;

      const { streamSpell } = useStreaming();

      try {
        const response = await $api<{ run: GraphRun; graph: Graph }>(
          `/api/spells/${this.spell?.id}/runs/${runId}`,
        );
        const { run, graph } = response;

        this.run = run;
        this.runGraph = graph;

        if (this.run?.state === RunState.inProgress) {
          streamSpell({ spellId: this.runGraph?.spellId, runId });
        }
      } catch (error) {
        console.error('Failed to load run:', error);
      }

      return this.run;
    },
    async fetchPermissions(spellId?: string) {
      if (!spellId) return null;
      const { data: permissions, error } = await useApi<
        PermissionWithTeam[],
        Error
      >(`/api/spells/${spellId}/permissions`);
      if (error.value || !permissions.value) {
        console.error('An error occurred:', error.value);
      } else {
        this.permissions = permissions.value;
      }
      return this.permissions;
    },
    async shareWithTeam(spellId: string, teamId: string) {
      const { data: permission, error } = await useApi<
        PermissionWithTeam[],
        Error
      >(`/api/spells/${spellId}/permissions`, {
        method: 'POST',
        body: { teamId },
      });
      if (error.value || !permission.value) {
        console.error('An error occurred:', error.value);
      } else {
        const { $clientPosthog } = useNuxtApp();
        $clientPosthog?.capture(PostHogEvents.UserSharedSpell, {
          spellId,
          teamId,
        });
        await refreshNuxtData(`spellPermissions/${spellId}`);
        clearNuxtData(`teamSpells/${permission.value?.teamId}`);
      }
    },
    async updatePermission(
      spellId: string,
      permissionId: string,
      role: AccessLevel,
    ) {
      const { data: permission, error } = await useApi<
        PermissionWithTeam[],
        Error
      >(`/api/spells/${spellId}/permissions/${permissionId}`, {
        method: 'PATCH',
        body: { role },
      });
      if (error.value || !permission.value) {
        console.error('An error occurred:', error.value);
      } else {
        await refreshNuxtData(`spellPermissions/${spellId}`);
        clearNuxtData(`teamSpells/${permission.value?.teamId}`);
      }
    },
    async removePermission(spellId: string, permissionId: string) {
      const { data: permission, error } = await useApi<
        PermissionWithTeam[],
        Error
      >(`/api/spells/${spellId}/permissions/${permissionId}`, {
        method: 'DELETE',
      });
      if (error.value || !permission.value) {
        console.error('An error occurred:', error.value);
      } else {
        await refreshNuxtData(`spellPermissions/${spellId}`);
        clearNuxtData(`teamSpells/${permission.value?.teamId}`);
      }
    },
    async loadVersions(spellId: string) {
      if (!spellId) {
        return;
      }

      const { data: versions, error } = await useCache<Graph[], Error>(
        `/api/spells/${spellId}/graphs`,
        { key: `graphs/${spellId}` },
      );
      if (error.value || !versions.value) {
        console.error('An error occurred:', error.value);
      } else {
        this.versions = versions.value;
      }

      return this.versions;
    },
    async checkTrigger() {
      try {
        await $api<Trigger>(
          `/api/spells/${this.spell?.id}/graphs/${this.liveGraph?.id}/trigger`,
          { method: 'POST' },
        );
      } catch (error) {
        console.error('An error occurred:', error);
      }
      return this.liveGraph;
    },
    async toggleTrigger(isEnabled?: boolean) {
      try {
        const updatedTrigger = await $api<Trigger>(
          `/api/spells/${this.spell?.id}/graphs/${this.liveGraph?.id}/trigger`,
          { method: 'PATCH', body: { isEnabled } },
        );
        this.trigger = updatedTrigger;
      } catch (error) {
        console.error('An error occurred:', error);
      }
      return this.liveGraph;
    },
    async updateSpell(spellId: string | null, updates: Partial<Spell>) {
      if (!spellId) return null;
      try {
        const updatedSpell = await $api<Spell>(`/api/spells/${spellId}`, {
          method: 'PATCH',
          body: updates,
        });
        this.spell = updatedSpell;
      } catch (error) {
        console.error('An error occurred:', error);
      }
      return this.spell;
    },
    async loadCollaborators(spellId: string) {
      const { data: collaborators, error } = await useApi<Collaborator[]>(
        `/api/spells/${spellId}/collaborators`,
      );
      if (error.value || !collaborators.value) {
        console.error('An error occurred:', error.value);
      } else {
        this.collaborators = collaborators.value;
      }
      return this.collaborators;
    },
    async startSpell(
      inputs: Json,
      type: 'test' | 'agent' | 'live',
      runName?: string,
    ) {
      const { streamSpell, stopStreaming } = useStreaming();
      const agentStore = useAgentsStore();

      this.run = undefined;
      this.runGraph =
        type === 'test'
          ? this.draftGraph
          : type === 'agent'
            ? agentStore.agentGraph
            : this.liveGraph;

      const spellId =
        type === 'agent' ? agentStore.agentSpellId : this.spell?.id;

      try {
        $api(`/api/spells/${spellId}/runs/${this.nextRunId}`, {
          method: 'POST',
          body: { inputs, runName },
          query: { graphId: this.runGraph?.id },
        });
        const { $clientPosthog } = useNuxtApp();
        $clientPosthog?.capture(
          type === 'test'
            ? PostHogEvents.SpellTest
            : type === 'agent'
              ? PostHogEvents.AgentCampaignStarted
              : PostHogEvents.SpellRunStarted,
          {
            spellId,
          },
        );

        await streamSpell({ spellId, runId: this.nextRunId, type });
      } catch (error) {
        stopStreaming();
        const { $clientPosthog } = useNuxtApp();
        if (error.statusCode === 429) {
          $clientPosthog?.capture(PostHogEvents.SpellRunFailed, {
            spellId,
            failureReason: 'Rate limit',
            error,
          });
          throw error;
        } else {
          $clientPosthog?.capture(PostHogEvents.SpellRunFailed, {
            spellId,
            failureReason: 'Unknown',
            error,
          });
          console.error('An error occurred:', error);
        }
      } finally {
        this.generateNextRunId();
      }

      return this.run;
    },
    async cancelSpell() {
      try {
        await $api(
          `/api/spells/${this.runGraph?.spellId}/runs/${this.run?.id}/cancel`,
          { method: 'POST' },
        );
      } catch (error) {
        console.error('An error occurred:', error);
      }
    },
    async testPreview() {
      const previewRun = this.template?.preview;
      if (!previewRun) return;

      this.isTesting = true;

      // Filter out start and display steps
      const dummySteps = previewRun.steps
        ?.filter(
          (stepRun: StepRun) =>
            !/^start(_\d+)?$/.test(stepRun.slug) &&
            !/^display(_\d+)?$/.test(stepRun.slug),
        )
        .sort(
          (a, b) =>
            new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime(),
        );

      if (dummySteps.length) {
        // Simulate step execution over time
        for (let i = 0; i < dummySteps.length; i++) {
          const runningStep = {
            ...dummySteps[i],
            state: 'inProgress',
            logs: [],
            endedAt: null,
            outputs: {},
          };

          // Add the step to the test run
          // We initialize the testRun with the first step so the spell run details are never blank
          if (i === 0) {
            this.testRun = {
              ...previewRun,
              steps: [runningStep],
              state: 'inProgress',
              outputs: {},
            } as GraphRun;
          } else {
            this.testRun.steps[i] = runningStep;
          }

          // Simulate log streaming
          for (const log of dummySteps[i].logs) {
            await new Promise((resolve) => setTimeout(resolve, 1000));
            this.testRun.steps[i].logs.push(log);
          }

          await new Promise((resolve) => setTimeout(resolve, 2000));

          // Mark the step as completed

          this.testRun.steps[i].outputs = dummySteps[i].outputs;
          this.testRun.steps[i].endedAt = dummySteps[i].endedAt;
          this.testRun.steps[i].state = 'success';
        }
        // Copy the outputs of the true testRun to the dummy run
        this.isTesting = false;
        this.testRun.outputs = previewRun.outputs;
        this.testRun.state = 'success';
      } else {
        setTimeout(() => {
          this.isTesting = false;
          this.testRun = previewRun;
        }, 1000);
      }
    },

    async createSpell({
      teamId: customTeamId,
      fromSpellId,
    }: {
      teamId?: string;
      fromSpellId?: string;
    } = {}) {
      const workspaceStore = useWorkspaceStore();
      const { personalTeam, workspace } = storeToRefs(workspaceStore);
      const userStore = useUserStore();

      const teamId = customTeamId ?? personalTeam.value?.id;

      try {
        const spell = await $api<Spell>('/api/spells', {
          method: 'POST',
          body: {
            workspaceId: workspace.value?.id,
            teamId,
            creatorId: userStore.user?.id,
            fromSpellId,
          },
        });

        if (spell) {
          const { $clientPosthog } = useNuxtApp();
          $clientPosthog?.capture(PostHogEvents.UserCreatedSpell, {
            creatorId: userStore.user?.id,
            teamId: teamId,
            spellId: spell.id,
            workspaceId: workspace.value?.id,
          });
        }

        clearNuxtData(`teamSpells/${teamId}`);

        await navigateTo({
          name: 'editor',
          params: { spellId: spell.id },
        });
      } catch (error) {
        console.error('An error occurred:', error);
      }

      return this.spell;
    },
    async duplicateSpell(spellId: string, teamId?: string) {
      const workspaceStore = useWorkspaceStore();
      const { personalTeam } = storeToRefs(workspaceStore);
      const { $clientPosthog } = useNuxtApp();

      $clientPosthog?.capture(PostHogEvents.UserDuplicatedSpell, {
        spellId,
        teamId: teamId ?? personalTeam.value?.id,
      });

      return this.createSpell({
        teamId: teamId ?? personalTeam.value?.id,
        fromSpellId: spellId,
      });
    },
    async deleteSpell(spellId: string) {
      try {
        await $api(`/api/spells/${spellId}`, { method: 'DELETE' });
        await this.deleteRecentSpell(spellId);
        this.teamSpells = this.teamSpells.filter(
          (spell) => spell.id !== spellId,
        );
      } catch (error) {
        console.error('An error occurred:', error);
      }
    },
    resetSpellDetails() {
      this.spell = undefined;
      this.liveGraph = undefined;
      this.draftGraph = undefined;
      this.trigger = undefined;
      this.versions = [];
      this.run = undefined;
    },
    async loadWorkspaceSpells() {
      const workspaceStore = useWorkspaceStore();
      const { data: spells, error } = await useApi<Spell[]>(
        `/api/workspaces/${workspaceStore.workspace?.id}/spells`,
      );
      if (error.value || !spells.value) {
        console.error('An error occurred:', error.value);
      } else {
        this.workspaceSpells = spells.value;
      }
      return this.workspaceSpells;
    },
    async loadTeamSpells(teamId: string) {
      if (!teamId) return;

      const workspaceStore = useWorkspaceStore();

      const { data: spells, error } = await useApi<Spell[], Error>(
        `/api/workspaces/${workspaceStore.workspaceId}/teams/${teamId}/spells`,
      );

      if (error.value || !spells.value) {
        console.error('An error occurred:', error.value);
      } else {
        this.teamSpells = spells.value;
      }
      return this.teamSpells;
    },
    async loadSharedSpells() {
      const { data: spells, error } =
        await useApi<Spell[]>('/api/spells/shared');
      if (error.value || !spells.value) {
        console.error('An error occurred:', error.value);
      } else {
        this.sharedSpells = spells.value;
      }
      return this.sharedSpells;
    },
    async loadSavedSpells() {
      const { data: spells, error } =
        await useApi<Spell[]>('/api/spells/saved');

      if (error.value || !spells.value) {
        console.error('An error occurred:', error.value);
      } else {
        this.savedSpells = spells.value;
      }
      return this.savedSpells;
    },
    async searchTemplates(searchQuery = '', searchTags = []) {
      this.isSearching = true;

      const { data: templateResults, error } = await useApi<Template[]>(
        `/api/templates/search?query=${searchQuery}&tags=${searchTags}`,
      );
      if (error.value || !templateResults.value) {
        console.error('An error occurred:', error.value);
      } else {
        this.templateResults = templateResults.value;
        this.isSearching = false;
        return this.templateResults;
      }
    },
    async loadUnfinished() {
      try {
        const workspaceStore = useWorkspaceStore();
        const { workspaceId } = storeToRefs(workspaceStore);

        const { data } = await useApi(`/api/spells/recent`, {
          query: {
            recentSpellIds: this.recentSpellIds,
            workspaceId: workspaceId.value,
          },
        });

        const recentSpells = data.value;

        this.recentSpellIds = recentSpells.map((spell) => spell.id);

        return recentSpells;
      } catch (error) {
        console.error('An error occurred:', error);
      }
      return [];
    },
    async loadSuggested() {
      if (!this.relevantTags.length) {
        this.loadRelevantTags();
      }
      await this.searchTemplates('', this.relevantTags);
      return this.templateResults;
    },
    loadRelevantTags() {
      const userStore = useUserStore();
      const { profile } = storeToRefs(userStore);
      if (!profile.value) return [];

      const onboardingResults = [
        profile.value.role,
        profile.value.industry,
        profile.value.goal,
        profile.value.companySize,
        ...profile.value.tools,
      ];

      const tags = new Set<string>();

      onboardingResults.forEach((result) => {
        if (result && relevantTagsMap[result]) {
          relevantTagsMap[result].forEach((tag) => tags.add(tag));
        }
      });

      this.relevantTags = Array.from(tags);

      return this.relevantTags;
    },
    async createBulkGroup({ name }: { name: string }) {
      const { data: group, error } = await useApi<RunGroup>(
        `/api/spells/${this.spell?.id}/groups`,
        {
          method: 'POST',
          body: { name, graphId: this.liveGraph?.id },
        },
      );

      await refreshNuxtData(`bulkGroups/${this.spell?.id}`);

      await navigateTo({
        name: 'spell.bulk',
        params: { spellId: this.spell?.id, groupId: group.value?.id },
        replace: true,
      });
    },
    async importGroupRuns(
      groupId: string,
      inputs: Record<string, string>[],
      overwrite = false,
    ) {
      await useApi<RunGroup[]>(
        `/api/spells/${this.spell?.id}/groups/${groupId}/runs`,
        {
          method: 'POST',
          body: { inputs },
          query: {
            graphId: this.liveGraph?.id,
            workspaceId: this.spell?.workspaceId,
            overwrite,
          },
        },
      );

      await refreshNuxtData(`groupRuns/${groupId}`);
    },
    async loadBulkGroups() {
      const { data: groups, error } = await useApi<RunGroup[]>(
        `/api/spells/${this.spell?.id}/groups`,
      );
      if (error.value || !groups.value) {
        console.error('An error occurred:', error.value);
      } else {
        this.bulkGroups = groups.value;
      }
      return this.bulkGroups;
    },
    async loadGroupRuns(
      groupId: string,
      { page, pageSize }: { page: number; pageSize: number },
    ) {
      if (!groupId) return;

      const { data, error } = await useApi<{
        runs: GraphRun[];
        count: number;
      }>(`/api/spells/${this.spell?.id}/groups/${groupId}/runs`, {
        query: {
          page,
          pageSize,
        },
      });
      if (error.value || !data.value) {
        console.error('An error occurred:', error.value);
      } else {
        this.groupRuns = data.value.runs;
        this.groupRunCount = data.value.count;
      }
      return this.groupRuns;
    },
    async handleBulkRun(groupId: string, type?: 'unfinished' | 'all') {
      const { streamSpell } = useStreaming();

      try {
        if (type) {
          this.groupRuns = this.groupRuns.map((run: GraphRun) =>
            run.stepErrorOccured || type === 'all'
              ? {
                  ...run,
                  state: RunState.pending,
                  outputs: {},
                }
              : run,
          );
        }

        $api(`/api/spells/${this.spell?.id}/groups/${groupId}/start`, {
          method: 'POST',
          query: { type },
        });

        await streamSpell({ spellId: this.spell?.id, groupId });
      } catch (error) {
        if (error.statusCode === 429) {
          throw error;
        } else {
          console.error('An error occurred:', error);
        }
      } finally {
        this.bulkGroups = this.bulkGroups.map((group) =>
          group.id === groupId ? { ...group, state: RunState.success } : group,
        );
      }

      return this.groupRuns;
    },
    trackUnfinishedSpell() {
      if (!this.spell) return;

      // Start with the current list from the store
      let updatedRecentSpellIds = this.recentSpellIds.filter(
        (id: string) => id !== this.spell?.id,
      );

      // Add the newly visited spell to the front of the list
      updatedRecentSpellIds.unshift(this.spell?.id);

      // Trim the list to the 10 most recent items
      if (updatedRecentSpellIds.length > 10) {
        updatedRecentSpellIds = updatedRecentSpellIds.slice(0, 10);
      }

      // Update the store
      this.recentSpellIds = updatedRecentSpellIds;

      clearNuxtData('unfinishedSpells');
      return this.recentSpellIds;
    },
    async deleteRecentSpell(spellId: string) {
      if (!spellId) return;

      // Start with the current list from the store
      const updatedRecentSpellIds = this.recentSpellIds.filter(
        (id) => id !== spellId,
      );

      // Update the store
      this.recentSpellIds = updatedRecentSpellIds;

      await refreshNuxtData('unfinishedSpells');
    },
    async refresh() {
      // refresh spell
      clearNuxtData(`spell/${this.spell?.id}`);
      this.loadCompleteSpell(this.spell?.id as string);

      // refresh graphs
      clearNuxtData(`graphs/${this.spell?.id}`);
      this.loadVersions(this.spell?.id as string);
    },
    generateNextRunId() {
      this.nextRunId = createId(); // Generate a new unique ID
    },
    async loadSpellSchedules() {
      const { data: schedules, error } = await useApi<Schedule[]>(
        `/api/spells/${this.spell?.id}/graphs/${this.liveGraph?.id}/schedules`,
      );
      if (error.value || !schedules.value) {
        console.error('An error occurred:', error.value);
      } else {
        this.schedules = schedules.value;
      }
      return this.schedules;
    },
    async updateSchedule({
      id,
      name,
      inputs,
      state,
      schedule,
    }: {
      id?: string;
      state?: ScheduleState;
      name?: string;
      inputs?: Json;
      schedule?: string | null;
    }) {
      const { data: updatedSchedule, error } = await useApi<Schedule>(
        `/api/spells/${this.spell?.id}/graphs/${this.liveGraph?.id}/schedules${
          id ? `/${id}` : ''
        }`,
        {
          method: id ? 'PATCH' : 'POST',
          body: { name, inputs, schedule, state },
        },
      );
      await refreshNuxtData(`schedules/${this.spell?.id}`);
      return updatedSchedule.value;
    },
    async createSchedule({
      name,
      inputs,
      schedule,
    }: {
      name: string;
      inputs: Json;
      schedule: string;
    }) {
      return this.updateSchedule({ name, inputs, schedule });
    },
    async disableSchedule(id: string) {
      return this.updateSchedule({ id, state: ScheduleState.inactive });
    },
    async enableSchedule(id: string) {
      return this.updateSchedule({ id, state: ScheduleState.active });
    },
    async deleteSchedule(id: string) {
      await useApi(
        `/api/spells/${this.spell?.id}/graphs/${this.liveGraph?.id}/schedules/${id}`,
        {
          method: 'DELETE',
        },
      );
      await refreshNuxtData(`schedules/${this.spell?.id}`);
    },
    validateSpell() {
      const canvasStore = useCanvasStore();
      canvasStore.loadCanvas();

      const steps = this.liveGraph?.steps;

      steps?.forEach((step) => {
        canvasStore.validateStep(step.slug, step.key, step.options);
      });

      return canvasStore.errors;
    },
  },
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useSpellsStore, import.meta.hot));
}
