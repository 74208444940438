<script setup lang="ts">
import type { Graph, GraphRun, Trigger } from '@respell/database';
import { triggers } from '@respell/integrations';
import AppInput from '~/components/app/AppInput.vue';
import TriggerDetail from '~/components/spell/TriggerDetail.vue';
import { runLimitReached } from '~/util/notifications';
import { createOptionSchema } from '~/util/validation';

const props = defineProps<{
  graph: Graph;
  run?: GraphRun;
  size?: 'sm' | 'md';
  readonly?: boolean;
  trigger?: Trigger;
  errorCount?: number;
}>();

const modelValue = defineModel<Record<string, any>>();

const spellStore = useSpellsStore();
const toast = useToast();
const { isRunning, isTesting, nextRunId } = storeToRefs(spellStore);

const form = ref();
const { inEditor, routeName } = useRouteName();
const { stopStreaming } = useStreaming();

const triggerDefinition = computed(() => {
  return props.trigger
    ? triggers[props.trigger.service][props.trigger.event]
    : null;
});

const inputs = computed(() => {
  return props.trigger
    ? triggerDefinition.value?.outputs
    : Object.fromEntries(
        Object.entries(props.graph?.inputs ?? {})
          .filter(([, input]) => !input.metadata?.forReview)
          ?.sort((a, b) => {
            const orderA = a[1].order ?? Infinity;
            const orderB = b[1].order ?? Infinity;
            return orderA - orderB;
          }),
      );
});

const inputForm = reactiveComputed(() => {
  const form = {};
  if (inputs.value) {
    Object.entries(inputs.value ?? {})
      .forEach(([key, value]) => {
        form[key] = props.run
          ? props.run.inputs?.[key]
          : triggerDefinition.value?.example?.[key] ?? value.value;
      })
      ?.sort((a, b) => {
        const orderA = a[1].order ?? Infinity;
        const orderB = b[1].order ?? Infinity;
        return orderA - orderB;
      });
  }
  return form;
});

const isPreview = computed(() => routeName.value === 'preview');

const inputSchema = computed(() =>
  createOptionSchema(props.readonly ? undefined : inputs.value),
);

const buttonLabel = computed(() => {
  if (inEditor.value) {
    if (isRunning.value) {
      return 'Testing...';
    }
    return 'Test';
  } else {
    if (isRunning.value) {
      return 'Running...';
    }
    return 'Run';
  }
});

const handleRun = async () => {
  try {
    await spellStore.startSpell(
      { ...inputForm },
      inEditor.value ? 'test' : 'live',
    );
  } catch (error) {
    if (error.statusCode === 429) {
      toast.add(runLimitReached);
    } else {
      console.error('An error occurred:', error);
    }
  }
};

onBeforeUnmount(() => {
  stopStreaming();
});
</script>
<template>
  <UForm
    ref="form"
    :schema="inputSchema"
    :state="modelValue ?? inputForm"
    class="w-full"
  >
    <UCard
      class="w-full"
      :ui="{
        rounded: 'rounded-2xl',
        divide: 'divide-y-0',
        header: {
          padding: '!pb-0',
        },
        body: {
          base: `flex flex-col gap-${size === 'sm' ? '3' : '4'}`,
          padding: size === 'sm' ? 'sm:pt-4' : 'sm:p-6',
        },
      }"
    >
      <template #header>
        <UAlert
          v-if="errorCount && errorCount > 0"
          icon="i-ph-warning-circle"
          color="yellow"
          variant="solid"
          :title="`${errorCount} issue${errorCount > 1 ? 's' : ''} to resolve`"
          class="w-full mb-5"
          :ui="{
            title: 'font-bold',
            variant: {
              solid: 'bg-yellow-100 text-yellow-700',
            },
          }"
        >
          <template #description>
            <p class="text-yellow-700">
              Resolve and republish before running this spell.
              <NuxtLink class="font-bold underline" :to="{ name: 'editor' }"
                >Edit spell</NuxtLink
              >
            </p>
          </template>
        </UAlert>
        <span class="flex justify-start gap-2">
          <span
            class="bg-primary-200 rounded-full flex"
            :class="size === 'sm' ? 'p-1.5' : 'p-2'"
          >
            <UIcon
              :name="trigger ? 'i-ph-lightning-fill' : 'i-ph-sparkle-fill'"
              class="text-primary-500"
              :class="size === 'sm' ? 'text-lg' : 'text-xl'"
            />
          </span>
          <p :class="size === 'sm' ? 'subtitle' : 'title'">
            {{ trigger ? 'Trigger' : 'Input' }}
          </p>
        </span>
      </template>

      <div v-if="props.readonly && run" class="contents">
        <TriggerDetail v-if="trigger" :graph="graph" :run="run" />

        <AppInput
          v-for="(input, key) in inputForm"
          v-else
          :key="key"
          :model-value="input"
          :option="inputs?.[key]"
          owner-type="GraphRun"
          :owner-id="run.id"
          readonly
        />
      </div>

      <div v-else class="contents">
        <UAlert
          v-if="!Object.keys(inputs ?? {}).length"
          title="No inputs"
          description="You can add inputs to the start step from the editor"
          color="gray"
          variant="soft"
          :ui="{
            title: 'font-bold',
          }"
        />

        <div v-for="(input, key) in inputs" v-else :key="key" class="contents">
          <AppInput
            v-if="modelValue"
            v-model="modelValue[key]"
            :option="input"
            class="w-full"
            owner-type="Spell"
            owner-id="spellId"
            :size="size"
          />

          <AppInput
            v-else
            v-model="inputForm[key]"
            :option="input"
            owner-type="GraphRun"
            :owner-id="nextRunId"
            class="w-full"
            :size="size"
          />
        </div>
      </div>

      <template v-if="!modelValue && (!props.readonly || isPreview)" #footer>
        <UButton
          v-if="isPreview"
          icon="i-ph-play-circle-bold"
          size="xl"
          color="primary"
          variant="outline"
          :label="isTesting ? 'Testing...' : 'Test Spell'"
          :loading="isTesting"
          block
          @click="spellStore.testPreview"
        />
        <span v-else class="flex gap-2 justify-start">
          <UTooltip
            text="Proceed with errors"
            :prevent="!form?.errors?.length"
            :ui="{
              background: 'bg-red-500',
            }"
          >
            <UButton
              icon="i-ph-play-circle-bold"
              size="xl"
              color="primary"
              variant="solid"
              :label="buttonLabel"
              :loading="isRunning"
              :ui="{
                rounded: 'rounded-full',
                font: 'font-bold',
                size: { xl: 'text-lg' },
                gap: { xl: 'gap-1.5' },
              }"
              @click="handleRun"
            />
          </UTooltip>
          <UTooltip
            v-if="isRunning"
            text="Cancel run"
            :ui="{
              background: 'bg-red-500',
            }"
          >
            <UButton
              icon="i-ph-stop-circle-bold"
              color="red"
              size="xl"
              :padded="false"
              variant="link"
              @click="spellStore.cancelSpell"
            />
          </UTooltip>
        </span>
      </template>
    </UCard>
  </UForm>
</template>
