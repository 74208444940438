import { StepDefinition } from '../../types';

export default {
  key: 'anon_read_instagram_post',
  name: 'Read Instagram Post',
  description: 'Reads an Instagram post.',
  icon: 'i-skill-icons-instagram',
  category: 'integrations',
  services: ['instagram'],
  options: {
    post: {
      key: 'post',
      name: 'Post',
      description: 'The link to the post that you would like to read',
      type: 'text/plain',
      isOptional: false,
      listDepth: 0,
      value: null,
    },
  },
  outputs: {
    image: {
      key: 'image',
      name: 'Image',
      description: 'A URL to the image',
      type: 'file/image',
      isOptional: true,
      listDepth: 0,
      value: null,
    },
    metadata: {
      key: 'metadata',
      name: 'Post Metadata',
      description: 'object',
      isOptional: false,
      type: 'object',
      listDepth: 0,
      value: null,
      schema: {
        poster: {
          key: 'poster',
          name: 'Poster',
          description: 'The person who posted the image',
          type: 'text/plain',
          isOptional: true,
          listDepth: 0,
          value: null,
        },
        description: {
          key: 'description',
          name: 'Description',
          description: 'The caption of the post',
          type: 'text/plain',
          isOptional: true,
          listDepth: 0,
          value: null,
        },
        likes: {
          key: 'likes',
          name: 'Likes',
          description: 'The amount of likes on the post',
          type: 'number/integer',
          isOptional: true,
          listDepth: 0,
          value: null,
        },
        id: {
          key: 'id',
          name: 'ID',
          description: 'The id of the post',
          type: 'text/plain',
          isOptional: true,
          listDepth: 0,
          value: null,
        },
        comments: {
          key: 'comments',
          name: 'Comments',
          description: 'The comments on the post',
          type: 'object',
          isOptional: true,
          listDepth: 1,
          value: null,
          metadata: {
            schema: {
              user: {
                key: 'user',
                name: 'User',
                description: 'The user who posted the comment',
                type: 'text/plain',
                isOptional: true,
                listDepth: 0,
                value: null,
              },
              content: {
                key: 'content',
                name: 'Content',
                description: 'The text content of the comment',
                type: 'text/plain',
                isOptional: true,
                listDepth: 0,
                value: null,
              },
            },
          },
        },
      },
    },
  },
} satisfies StepDefinition;
