import { StepDefinition } from '../../types';

export default {
  key: 'linkedin_create_post',
  name: 'Create LinkedIn Post',
  description: 'Create a post on LinkedIn with an optional file attachment',
  icon: 'i-logos-linkedin-icon',
  category: 'integrations',
  services: ['linkedin'],
  options: {
    content: {
      key: 'note',
      name: 'Note',
      description: 'The content of the linkedin post',
      type: 'text/plain',
      isOptional: false,
      metadata: {
        icon: 'i-ph-subtitles-bold',
      },
      listDepth: 0,
      value: null,
    },
    media: {
      key: 'media',
      name: 'Media',
      description: 'The media you would like to attach to the linkedin post',
      type: 'file/image',
      isOptional: true,
      metadata: {
        icon: 'i-ph-subtitles-bold',
      },
      listDepth: 0,
      value: null,
    },
  },
  outputs: {},
} satisfies StepDefinition;
