// Supported filetypes
export default [
  {
    type: '.pdf',
    description: 'PDF (.pdf)',
  },
  {
    type: '.docx',
    description: 'DOCX (.docx)',
  },
  {
    type: '.txt',
    description: 'Text (.txt)',
  },
];
