import { StepDefinition } from '../../types';

export default {
  key: 'add_contact',
  name: 'Add Contact To Campaign',
  description: 'Adds a contact to an Instantly campaign',
  icon: 'i-respell-instantly',
  category: 'integrations',
  services: ['coda'],
  options: {
    campaign_id: {
      key: 'campaign_id',
      name: 'List Name',
      description: 'The name of the list you want to add this email to.',
      type: 'text/plain',
      isOptional: true,
      listDepth: 0,
      metadata: {
        icon: 'i-ph-text-align-left',
        options: {
          reference: {
            namespace: 'coda',
            member: 'list_campaigns_instantly',
          },
        },
        schema: {
          name: {
            key: 'name',
            type: 'text/plain',
            name: 'List Name',
            isOptional: true,
            listDepth: 0,
            value: null,
          },
          id: {
            key: 'id',
            type: 'text/plain',
            name: 'List ID',
            isOptional: true,
            listDepth: 0,
            value: null,
          },
        },
      },
      value: null,
    },
    first_name: {
      key: 'first_name',
      name: 'First Name',
      description: 'The first name of contact to be added to the list.',
      type: 'text/plain',
      isOptional: false,
      listDepth: 0,
      value: null,
    },
    last_name: {
      key: 'last_name',
      name: 'Last Name',
      description: 'The last name of contact to be added to the list.',
      type: 'text/plain',
      isOptional: false,
      listDepth: 0,
      value: null,
    },
    email: {
      key: 'email',
      name: 'Email',
      description: 'The email of the contact to be added to the list.',
      type: 'text/email',
      isOptional: false,
      listDepth: 0,
      value: null,
    },
  },
  outputs: {},
} satisfies StepDefinition;
