import type { User as BaseUser, Profile } from '@respell/database';
import { isDev } from '@respell/utils/config';
import { acceptHMRUpdate, defineStore } from 'pinia';
import { useSpellsStore } from './spells';
import { useWorkspaceStore } from './workspaces';

type User = BaseUser & {
  profile: Profile;
};

export const allMilestones = [
  'questionnaire',
  'tutorial',
  'createSpell',
  'testSpell',
  'publishSpell',
  'shareSpell',
  'joinedSlack',
] as const;

export const useUserStore = defineStore('user', () => {
  const { $clientPosthog } = useNuxtApp();

  const user = ref<User | undefined>(undefined);
  const profile = ref<Profile | undefined>(undefined);
  const relevantTags = ref([]);

  const workspaceStore = useWorkspaceStore();
  const spellStore = useSpellsStore();

  const hasCompletedMilestones = computed(() => {
    return (
      profile.value?.milestones &&
      allMilestones.every((milestone) =>
        profile.value?.milestones.includes(milestone),
      )
    );
  });

  const identify = () => {
    $clientPosthog?.identify(user.value?.email);
    $clientPosthog?.setPersonPropertiesForFlags({
      currentSubscription: workspaceStore.subscriptionType,
      email: user.value?.email,
    });
  };

  const isEnabled = (feature: string) => {
    // This corresponds to the posthog config in nuxt.config.ts
    if (isDev) {
      return true;
    } else {
      return $clientPosthog?.isFeatureEnabled(feature);
    }
  };

  const activeWorkspace = computed(() => {
    return workspaceStore.workspace;
  });

  async function login(credentials: { email: string; password: string }) {
    const { signIn } = useAuth();
    const response = await signIn('credentials', {
      ...credentials,
      redirect: false,
    });

    return response;
  }

  async function register(registerCredentials: {
    fullName: string;
    email: string;
    password: string;
  }) {
    const { data: userData, error } = await useApi(`/api/register`, {
      method: 'POST',
      body: registerCredentials,
    });
    if (error.value || !userData.value) {
      console.error(error.value);
    }

    return userData.value;
  }

  function initializeAbility() {
    const { $updateAbility } = useNuxtApp();
    $updateAbility(user.value as User);
  }

  async function loadUser() {
    const { data: userData, error } = await useApi<User>('/api/me');
    if (error.value || !userData.value) {
      console.error(error);
    } else {
      user.value = userData.value;
      profile.value = userData.value.profile;

      identify();
      initializeAbility();
    }

    return user.value;
  }

  function logout() {
    const { signOut } = useAuth();

    workspaceStore.workspaceId = undefined;
    spellStore.recentSpellIds = [];
    $clientPosthog?.reset();

    signOut();
  }

  async function updateProfile(updates: Partial<Profile>) {
    const { data: userData, error } = await useApi<User & { profile: Profile }>(
      `/api/users/${user.value?.id}/profile`,
      {
        method: 'patch',
        body: updates,
      },
    );
    if (error.value) {
      console.error(error);
    } else {
      const { profile: profileData, ...rest } = userData.value;
      user.value = rest;
      profile.value = profileData;
    }
  }

  async function markAsComplete(step: string) {
    if (profile.value && !profile.value.milestones.includes(step)) {
      const updatedMilestones = [...profile.value.milestones, step];

      await updateProfile({
        milestones: updatedMilestones,
      });
    }
  }

  return {
    user,
    profile,
    relevantTags,
    hasCompletedMilestones,
    activeWorkspace,
    login,
    register,
    loadUser,
    logout,
    identify,
    updateProfile,
    isEnabled,
    markAsComplete,
  };
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useUserStore, import.meta.hot));
}
