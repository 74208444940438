import { AbilityBuilder, PureAbility, subject } from '@casl/ability';
import type { PrismaQuery, Subjects } from '@casl/prisma';
import { accessibleBy, createPrismaAbility } from '@casl/prisma';
import {
  AccessLevel,
  MemberRole,
  type Folder,
  type GraphRun,
  type Member,
  type Spell,
  type Subscription,
  type Team,
  type Trigger,
  type User,
  type Workspace,
} from '@respell/database';

type DataSources = {};

type Actions =
  // | 'manage' // Warning: 'manage' is a reserved CASL keyword granting all access
  | 'read'
  | 'addMember'
  | 'dataSources'
  | 'delete'
  | 'edit'
  | 'editBilling'
  | 'editFiles'
  | 'viewFiles';

export type AppAbility = PureAbility<
  [
    Actions,
    Subjects<{
      Spell: Spell;
      GraphRun: GraphRun;
      Team: Team;
      User: User;
      Trigger: Trigger;
      Workspace: Workspace;
      Subscription: Subscription;
      Member: Member;
      Folder: Folder;
      DataSources: DataSources;
    }>,
  ],
  PrismaQuery
>;

const defineAbility = (user: User) => {
  const { build } = new AbilityBuilder<AppAbility>(createPrismaAbility);

  const ability = build();
  const rules = defineRulesForUser(user);
  ability.update(rules);

  return ability;
};

const defineRulesForUser = (user: User) => {
  const { can, cannot, rules } = new AbilityBuilder<AppAbility>(
    createPrismaAbility,
  );

  // Spell
  can('read', 'Spell', {
    isPublic: true,
  });

  if (user) {
    // Subscription Starter
    can('editBilling', 'Subscription', {
      type: 'starter',
    });

    // Subscription Team
    can('editBilling', 'Subscription', {
      type: 'team',
    });

    // Subscription Enterprise
    cannot('editBilling', 'Subscription', {
      type: 'enterprise',
    });

    // Spell
    const readSpellPermissions = {
      // Hide SDRAgent spells from all spell queries
      OR: [{ usedTemplateId: null }, { usedTemplateId: { not: 'sdragent' } }],
      spellPermissions: {
        some: {
          role: {
            in: [AccessLevel.viewer, AccessLevel.editor, AccessLevel.owner],
          },
          team: {
            is: {
              members: {
                some: {
                  userId: user.id,
                },
              },
            },
          },
        },
      },
    };

    const editSpellPermissions = {
      spellPermissions: {
        some: {
          role: {
            in: [AccessLevel.viewer, AccessLevel.editor, AccessLevel.owner],
          },
          team: {
            is: {
              members: {
                some: {
                  userId: user.id,
                },
              },
            },
          },
        },
      },
    };

    can('read', 'Spell', {
      ...readSpellPermissions,
    });

    can('edit', 'Spell', {
      ...editSpellPermissions,
    });

    can('delete', 'Spell', {
      ...editSpellPermissions,
    });

    // GraphRun
    can('read', 'GraphRun', {
      archivedAt: null,
      spell: {
        is: {
          ...readSpellPermissions,
        },
      },
    });

    // Trigger
    can('read', 'Trigger', {
      graph: {
        is: {
          spell: {
            is: {
              ...readSpellPermissions,
            },
          },
        },
      },
    });

    // Team
    can('read', 'Team', {
      members: {
        some: {
          userId: user.id,
          role: {
            in: [MemberRole.viewer, MemberRole.editor, MemberRole.admin],
          },
        },
      },
    });

    can('edit', 'Team', {
      members: {
        some: {
          userId: user.id,
          role: {
            in: [MemberRole.editor, MemberRole.admin],
          },
        },
      },
    });

    can('delete', 'Team', {
      type: 'custom',
      members: {
        some: {
          userId: user.id,
          role: {
            in: [MemberRole.editor, MemberRole.admin],
          },
        },
      },
    });

    can('addMember', 'Team', {
      type: 'custom',
      members: {
        some: {
          userId: user.id,
          role: {
            in: [MemberRole.editor, MemberRole.admin],
          },
        },
      },
    });

    // Workspace
    can('viewFiles', 'Workspace', {
      teams: {
        some: {
          members: {
            some: {
              userId: user.id,
              role: {
                in: [MemberRole.viewer, MemberRole.editor, MemberRole.admin],
              },
            },
          },
        },
      },
    });

    can('editFiles', 'Workspace', {
      adminOnlyUploads: false,
      teams: {
        some: {
          type: 'workspace',
          members: {
            some: {
              userId: user.id,
              role: {
                in: [MemberRole.editor, MemberRole.admin],
              },
            },
          },
        },
      },
    });

    can('editFiles', 'Workspace', {
      adminOnlyUploads: true,
      teams: {
        some: {
          type: 'workspace',
          members: {
            some: {
              userId: user.id,
              role: {
                in: [MemberRole.admin],
              },
            },
          },
        },
      },
    });

    can('read', 'Workspace', {
      teams: {
        some: {
          members: {
            some: {
              userId: user.id,
            },
          },
        },
      },
    });

    can('edit', 'Workspace', {
      teams: {
        some: {
          members: {
            some: {
              userId: user.id,
              role: {
                in: [MemberRole.admin],
              },
            },
          },
        },
      },
    });

    // Member
    can('edit', 'Member', {
      team: {
        is: {
          members: {
            some: {
              userId: user.id,
              role: {
                in: [MemberRole.admin],
              },
            },
          },
        },
      },
    });

    can('delete', 'Member', {
      role: {
        in: [MemberRole.viewer, MemberRole.editor],
      },
      team: {
        is: {
          members: {
            some: {
              userId: user.id,
              role: {
                in: [MemberRole.admin],
              },
            },
          },
        },
      },
    });
  }

  return rules;
};

export { accessibleBy, defineAbility, defineRulesForUser, subject };
