import { StepDefinition } from '../../types';

export default {
  key: 'linkedin_add_comment_to_post',
  name: 'Add comment to post',
  description: 'Comments on a specific post on LinkedIn',
  icon: 'i-logos-linkedin-icon',
  category: 'integrations',
  services: ['linkedin'],
  options: {
    post: {
      key: 'post',
      name: 'Post',
      description: 'The link to the post',
      type: 'text/plain',
      isOptional: false,
      metadata: {
        icon: 'i-ph-subtitles-bold',
      },
      listDepth: 0,
      value: null,
    },
    content: {
      key: 'comment',
      name: 'Comment',
      description: 'The text content of the comment',
      type: 'text/plain',
      isOptional: false,
      metadata: {
        icon: 'i-ph-subtitles-bold',
      },
      listDepth: 0,
      value: null,
    },
  },
  outputs: {},
} satisfies StepDefinition;
