import { StepDefinition } from '../../types';

export default {
  key: 'get_page',
  name: 'Get Page',
  description: 'Searches Notion for a page and returns its details.',
  icon: 'i-respell-notion',
  category: 'integrations',
  services: ['notion'],
  options: {
    pageId: {
      key: 'pageId',
      name: 'Page URL',
      description: 'The URL of the page you want to retrieve.',
      type: 'text/plain',
      isOptional: false,
      metadata: {
        icon: 'i-ph-subtitles-bold',
        options: {
          reference: {
            namespace: 'notion',
            member: 'list_pages',
          },
        },
        schema: {
          id: {
            key: 'id',
            name: 'Page ID',
            type: 'text/plain',
            isOptional: false,
            listDepth: 0,
            value: null,
          },
        },
      },
      listDepth: 0,
      value: null,
    },
  },
  outputs: {
    content: {
      key: 'content',
      name: 'Content',
      description: 'Content of the retrieved page.',
      type: 'text/plain',
      isOptional: false,
      listDepth: 0,
      value: null,
    },
  },
} satisfies StepDefinition;
